/**
 * The type of device
 */
export enum DeviceType {
    /**
     * A mobile device like a smartphone
     */
    Handset = 'handset',

    /**
     * A mobile device like a tablet
     */
    Tablet = 'tablet',

    /**
     * A desktop computer
     */
    Desktop = 'desktop',

    /**
     * A laptop computer
     */
    Laptop = 'laptop',

    /**
     * Could not recognize what type of device it is
     */
    Unknown = 'unknown',
}
