/**
 * Language Code ISO (ISO 639-1) and Country Code ISO (ISO 3166-1)
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * https://en.wikipedia.org/wiki/ISO_3166-1
 */

/**
 *
 * Language Tags (from backend)
 */
export enum BackendLanguageTag {
    /**
     * Portuguese from Portugal
     */
    ptPT = 'pt-PT',

    /**
     * Spanish from Spain
     */
    esES = 'es-ES',

    /**
     * English from United States
     */
    enUS = 'en-US',
}
