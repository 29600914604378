import moment from 'moment';
import { ActivityEndType } from '../backend/enums/activity-end-type';
import { AffiliationType } from '../backend/enums/affiliation-type';
import { BackendLanguageTag } from '../backend/enums/backend-language-tag';
import { CameraFacing } from '../backend/enums/camera-facing';
import { CaregiverRelationshipRole } from '../backend/enums/caregiver-relationship-role';
import { DeviceType } from '../backend/enums/device-type';
import { InviteState } from '../backend/enums/invite-state';
import { ProcessStatus } from '../backend/enums/process-status';
import { RegionCode } from '../backend/enums/region-code';
import { RelationshipType } from '../backend/enums/relationship-type';
import { ReminderResponseValue } from '../backend/enums/reminder-response-value';
import { UserGender } from '../backend/enums/user-gender';
import { UserType } from '../backend/enums/user-type';
import { MomentLanguageCode } from '../enums/moment-language-code';
import { SignInError } from '../enums/sign-in-error';
import { LanguageTranslation } from './types';

// Overriding
// https://github.com/moment/moment/blob/develop/locale/en-gb.js
moment.updateLocale(MomentLanguageCode.enGB, {
    longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
});

export const en_US: LanguageTranslation = {
    legal_documents: {
        terms_and_conditions: 'Terms and Conditions',
        privacy_policy: 'Privacy Policy',
    },
    products: {
        bluebird: 'Sioslife',
        bluebird_with_gender: 'to Sioslife',
    },
    relationship_types: {
        [RelationshipType.Family]: 'Family',
        [RelationshipType.Friend]: 'Friend',
        [RelationshipType.Healthcare]: 'Healthcare',
        [RelationshipType.Service]: 'Service',
        [RelationshipType.Other]: 'Other',
    },
    user_types: {
        [UserType.Caregiver]: 'Caregiver',
        [UserType.Follower]: 'Follower',
        [UserType.Senior]: 'Senior',
    },
    genders: {
        [UserGender.Female]: 'Female',
        [UserGender.Male]: 'Male',
        [UserGender.Undisclosed]: 'Undisclosed',
        [UserGender.Unspecified]: 'Other',
    },
    languages: {
        [BackendLanguageTag.ptPT]: 'Portuguese (Portugal)',
        [BackendLanguageTag.enUS]: 'English (US)',
        [BackendLanguageTag.esES]: 'Spanish (Spain)',
    },
    regions: { [RegionCode.PT]: 'Portugal', [RegionCode.ES]: 'Spain' },
    roles: {
        [CaregiverRelationshipRole.Administrator]: 'Administrator',
        [CaregiverRelationshipRole.Contact]: 'Contact',
        [CaregiverRelationshipRole.Member]: 'Member',
    },
    religions: {
        african_traditional_and_diasporic: 'African traditional and diasporic',
        agnostic: 'Agnostic',
        animism: 'Animism',
        atheist: 'Atheist',
        baha_i: 'Baháʼí',
        buddhism: 'Buddhism',
        cao_dai: 'Caodaism',
        chinese_traditional: 'Chinese traditional',
        christianity: 'Christianity',
        hinduism: 'Hinduism',
        islam: 'Islam',
        jainism: 'Jainism',
        juche: 'Juche',
        judaism: 'Judaism',
        neo_paganism: 'Neopaganism',
        nonreligious: 'Non religious',
        rastafari: 'Rastafari',
        secular: 'Secular',
        shinto: 'Shinto',
        sikhism: 'Sikhism',
        spiritism: 'Spiritism',
        tenrikyo: 'Tenrikyo',
        unitarian_universalism: 'Unitarian universalism',
        zoroastrianism: 'Zoroastrianism',
        primal_indigenous: 'Indigenous',
        undisclosed: 'Undisclosed',
        unspecified: 'Other',
    },
    affiliation_types: {
        [AffiliationType.Senior]: 'Senior',
        [AffiliationType.Administrator]: 'Administrator',
        [AffiliationType.Manager]: 'Manager',
        [AffiliationType.Operator]: 'Operator',
        [AffiliationType.MedicalProfessional]: 'Medical professional',
    },
    date_time_picker: {
        cancel_button: 'Cancel',
        confirm_button: 'Confirm',
    },
    login: {
        welcome: 'Hello Caregiver!',
        welcome_accept_invite: 'Login to your account to accept the invitation!',
        instruction: 'Enter your credentials to get started.',
        email_input: 'Email address',
        password_input: 'Password',
        enter_button: 'Enter',
        forgot_password: 'Forgot your password?',
        validate_invite: 'Were you invited to be a caregiver?',
        use_invite_code: 'Use invitation code',
        login_blocked_instruction:
            'Download and install the {{appName}} application to be able to communicate with your friends and family and enjoy all the features.',
        google_play_store_button: 'Download Android',
        apple_store_button: 'Download iOS',
        errors: {
            invalid_password: 'Invalid password',
            [SignInError.Unknown]: 'An error occurred. Please, try again later.',
            [SignInError.InvalidEmail]: 'Invalid email address',
            [SignInError.UserDisabled]: 'User disabled',
            [SignInError.UserNotFound]: 'User not found',
            [SignInError.WrongPassword]: 'Wrong password',
            [SignInError.FailedToGetMe]: 'Could not load user information. Please, contact the Support team.',
        },
    },
    recover_password: {
        title: 'Forgot your password?',
        subtitle: 'We will help you!',
        instructions:
            'In order to be able to define a new password, please enter your email address. We will send you a message with a link that will allow you to make the change.',
        email_input: 'Email address',
        send_button: 'Send',
        password_remembered: 'After all, I remembered my password!',
        go_back_button: 'Go back to the login screen',
        errors: {
            invalid_email: 'Invalid email',
            send_request_error_message: 'An error occurred. It was not possible to send the request.',
        },
        success: {
            send_request_success_message:
                'The instructions for changing your password have been sent to the email address you provided.',
        },
    },
    replace_password: {
        title: 'Set new password',
        instructions:
            'Ok! Good job! We will then define a new password. Type your new password in the two fields below to finish the process.',
        new_password_input: 'New password',
        repeat_password_input: 'Repeat the new password',
        submit_button: 'Confirm',
        password_remembered: 'After all, I remembered my password!',
        go_back_button: 'Go to the login screen',
        errors: {
            invalid_password_minimum_characters: 'The password must have at least 6 characters',
            invalid_password_blank_spaces: "The password can't start or end with blank spaces",
            invalid_repeat_password_empty: 'Repeat the password',
            invalid_repeat_password_mismatch: 'Please make sure the passwords match',
            change_password_error_message: 'An error occurred. It was not possible to change your password.',
        },
        success: {
            change_password_success_message: 'Your password was changed. You can now sign in with your new password.',
        },
    },
    validate_invite: {
        title: 'Use invitation code',
        instructions_1: 'To be a {{brand}} caregiver you must have an invitation to access the platform.',
        instructions_2:
            'If you have already requested an invitation, please check your email message box and/or your SMS message box and look for a {{brand}} message. In that message you will find the code you should indicate below.',
        code_input: 'Invitation code',
        send_button: 'Validate invitation and continue',
        errors: {
            invalid_code: 'Invalid code',
            invalid_invite_error_message:
                'The invitation you tried to use is invalid. Please, make sure you have entered the correct code. If so, request a new invitation.',
        },
    },
    accept_relationship_invite: {
        title: 'Accept the invitation to be the caregiver of',
        instructions:
            'To be a caregiver, you must have an account to access the {{brand}} platform. If this is your first time accepting an invitation, press "{{button_create}}". If you have already accepted other invitations in the past, use your existing account and press "{{button_login}}".',
        create_account_button: 'Accept and create new account',
        login_button: 'Log into an existing account',
        errors: {
            invalid_invite_error_message:
                'The invitation you tried to use is invalid. Please, request a new invitation.',
        },
    },
    notifications: {
        date: 'Date',
        time: 'Time',
        title: 'Notifications',
        exit_button: 'Exit',
        read_all_notifications_normal_button: 'Mark all as read',
        read_all_notifications_loading_button: 'Reading...',
        read_all_notifications_success_button: 'Notifications read!',
        read_all_notifications_error_button: 'Error while reading notifications',
        read_all_notifications_dialog_title: 'Mark all notifications as read?',
        read_all_notifications_dialog_content:
            'You can individually re-mark each notification as unread later if you wish.',
        read_all_notifications_dialog_action_yes_button: 'Yes, mark',
        read_all_notifications_dialog_action_no_button: 'Cancel',
        read_all_notifications_disabled_tooltip_message: "You've already marked all notifications as read.",
        success: {
            read_all_notifications_success_message: 'The notifications have all been marked as read.',
        },
    },
    push_notifications: {
        warnings: {
            unsubscribed_generic_warning_title: 'You cannot receive notifications in real-time',
            unsubscribed_not_supported_warning_message:
                'The browser you are using does not support the reception of notifications. Use Chrome, Firefox or Edge to receive notifications in real-time.',
            unsubscribed_error_warning_message:
                'An error has occurred so you cannot receive notifications in real-time.',
            unsubscribed_permissions_denied_warning_message:
                'The permissions to receive notifications were denied. Please, enable notifications for the browser you are using.',
        },
    },
    relationship_invite_modal: {
        title: 'Accept the invitation to be caregiver',
        relationship_type_input: 'What type of relationship do you have with {{name}}?',
        alert_description:
            'You are using an account with information different from the information present in this invitation. Please, review carefully if you intend to continue.',
        accept_button: 'Yes, I accept',
        decline_button: 'No, I refuse',
        relationship_already_exists_description:
            "You are already a caregiver for having previously accepted an invitation. You don't need to do anything else.",
        invite_done_used_description: "This invitation was already used. You don't need to do anything else.",
        invite_done_refused_description: "This invitation was already refused. You don't need to do anything else.",
        invite_done_accepted_description: "This invitation was already accepted. You don't need to do anything else.",
        loading_invite_description_1: 'Loading invitation details...',
        loading_invite_description_2: 'Please wait...',
        errors: {
            refuse_invite_error_message: 'An error occurred. It was not possible to refuse the invitation.',
            accept_invite_error_message: 'An error occurred. It was not possible to accept the invitation.',
            fetch_relationship_invite_error_message: 'An error occurred. It was not possible to open the invitation.',
            fetch_relationship_invite_invalid_error_message: 'The invitation you tried to open is invalid.',
        },
        success: {
            refuse_invite_success_message: 'The invitation was refused. {{name}} was not added to your contacts list.',
            accept_invite_success_message: 'The invitation was accepted. {{name}} was added to your contacts list. ',
        },
    },
    settings: {
        title: 'Settings',
        exit_button: 'Exit',
        personal_information_section_title: 'My profile',
        change_theme_section_title: 'My theme',
        legal_information_section_title: 'Legal information',
        relationships_section_title: 'My relationships',
        affiliations_section_title: 'My affiliations',
        logout_normal_button: 'Logout',
        logout_loading_button: 'Logging out...',
        logout_error_button: 'Error while logging out',
        theme_input: 'Theme',
        dark_option: 'Dark',
        light_option: 'Light',
        system_option: 'System theme (auto)',
    },
    create_informal_caregiver_account: {
        register_title: 'Registration',
        essential_information_step_description:
            'Welcome to the {{brand}} platform! We will create a user account so that you can become a caregiver and thus finish the process of accepting the invitation you received.',
        essential_information_step: 'Essential information',
        personal_information_step: 'Personal information',
        optional_information_step: 'Optional information',
        note_title: 'Note',
        note_statement: 'The data indicated with "*" are mandatory for the creation of the caregiver access account.',
        continue_button: 'Continue',
        create_account_button: 'Accept invitation and create account',
        start_using_button: 'Start using',
        go_back_to_login_button: 'Go to the login screen',
        go_back_button: 'Go back',
        success_title: 'Success!',
        success_description:
            'Congratulations! You have just become a user of the {{brand}} platform! Thank you for joining this community. You can now go to your user account.',
        error_title: 'Unable to create the user account!',
        error_description:
            'Unfortunately something failed while creating your user account. Please, contact the Support team for help.',
        relationship_type_input: 'Relationship type with {{name}} *',
        email_address_input: 'Email address',
        password_input: 'Password',
        repeat_password_input: 'Repeat password',
        legal_documents_title: 'Legal documents',
        legal_documents_statement:
            'I confirm that I have read and accept the <0>General Conditions of Use</0> and the <1>Privacy and Data Protection Policy</1>.',
        name_input: 'Name',
        mobile_number_input: 'Mobile phone number',
        profile_picture_input: 'Profile picture',
        gender_input: 'Gender',
        gender_placeholder_input: 'Select a gender',
        language_input: 'Language',
        language_placeholder_input: 'Select a language',
        birthday_input: 'Birthday',
        birthday_input_placeholder: 'Select a date',
        tax_identification_number_input: 'Tax identification number',
        errors: {
            invalid_email: 'Invalid email',
            invalid_password_minimum_characters: 'The password must have at least 6 characters',
            invalid_password_blank_spaces: "The password can't start or end with blank spaces",
            invalid_repeat_password_empty: 'Repeat the password',
            invalid_repeat_password_mismatch: 'Please make sure the passwords match',
            invalid_name: 'Invalid name',
            invalid_name_maximum_characters: 'The name must have less than {{max}} characters',
            invalid_mobile_phone_number: 'Invalid mobile phone number',
            invalid_tax_identification_number: 'Invalid tax identification number',
            empty_mobile_phone_number: 'Mobile phone number missing',
            legal_documents_not_accepted: 'It is mandatory to accept the legal documents',
            enter_account_error_message: 'An error occurred. It was not possible to enter into your account.',
            invalid_invite_error_message: 'The invitation you tried to use to create an account is invalid.',
        },
    },
    office: {
        profile_type_tabs: {
            informal_caregiver: 'Informal',
            professional_caregiver: 'Professional',
            system_administrator: 'Sys Admin',
            partner: 'Partner',
        },
        informal_caregiver: {
            tabs: {
                activity: 'Activity',
                profile: 'Profile',
                media: 'Multimedia',
                reminders: 'Reminders',
            },
            add_menu: {
                button: 'Add',
                title: 'What do you want to add?',
                create_reminder: 'Reminder',
                send_photo: 'Photo',
                send_video: 'Video',
                send_invite: 'Caregiver invitation',
            },
            relationship_picker: {
                caption: 'You are seeing the profile of:',
                dialog_title: 'Select another contact',
            },
        },
        professional_caregiver: {
            tabs: {
                start: 'Home',
                seniors: 'Seniors',
                applications: 'Applications',
                services: 'Services',
                professional_caregivers: 'Caregivers',
                activity: 'Activity',
                devices: 'Devices',
            },
            affiliation_picker: {
                caption: 'You are working on entity:',
                dialog_title: 'Select another entity',
            },
        },
        system_administrator: {
            tabs: {
                start: 'Home',
                analytics: 'Analytics',
                activity: 'Activity',
                devices: 'Devices',
                apiDocs: 'API Docs',
                applications: 'Applications',
                entities: 'Entities',
            },
        },
        partner: {
            tabs: {
                start: 'Home',
                apiDocs: 'API Docs',
            },
        },
        legacy_integration: {
            entity_services_disabled_alert_title: 'Unable to perform the action.',
            entity_services_disabled_alert_message:
                "Sorry, you don't have access to this area. Please, contact the Support team for more information.",
        },
    },
    seniors: {
        root: {
            title: 'Seniors',
            add_button: 'Add senior',
            search_input_placeholder: 'Search seniors',
            name_header: 'Name',
            gender_header: 'Gender',
            state_header: 'State',
            state_value_true: 'Active',
            state_value_false: 'Inactive',
            no_seniors: 'There are no seniors.',
            deleted_senior_dialog_title: 'Senior "{{name}}\'s" profile deleted',
            deleted_senior_dialog_message: "The senior's profile no longer exists in the entity's list of seniors.",
            deleted_senior_dialog_ok_button: 'Ok',
        },
        edit: {
            tabs: {
                profile: 'Profile',
                caregivers: 'Caregivers',
                applications: 'Applications',
                devices: 'Devices',
                activity: 'Activity',
                analytics: 'Analytics',
                cards: 'Cards',
                health: 'Health',
            },
            caregivers: {
                invite_caregiver_button: 'Invite caregiver',
                invites_history_button: 'See invitations',
                no_caregivers: 'There are no caregivers.',
                name_header: 'Name',
                relationship_type_header: 'Type',
                remove_relationship_header: 'Remove relationship',
                remove_relationship_dialog_title:
                    'Remove the relationship between the senior {{senior}} and the caregiver {{caregiver}}?',
                remove_relationship_dialog_message:
                    'By removing this relationship, the senior {{senior}} will no longer be able to communicate with the caregiver {{caregiver}}.',
                remove_relationship_dialog_action_yes_button: 'Yes, remove',
                remove_relationship_dialog_action_no_button: 'Cancel',
                errors: {
                    remove_relationship_error_message:
                        'An error occurred. It was not possible to remove the relationship.',
                },
            },
            invites_history: {
                title: 'Invitations history',
                no_invites: 'There are no invitations.',
                name_header: 'Name',
                contact_header: 'Contact',
                state_header: 'Invitation state',
                status_header: 'Process status',
                cancel_invite_dialog_title: "Cancel the invitation for {{caregiver}} to be {{senior}} caregiver's?",
                cancel_invite_dialog_message:
                    'By cancelling this invitation, the caregiver {{caregiver}} will not be able to accept the invitation to communicate with the senior {{senior}}.',
                cancel_invite_dialog_action_yes_button: 'Yes, cancel',
                cancel_invite_dialog_action_no_button: "Dont't change the invitation",
                errors: {
                    cancel_invite_error_message: 'An error occurred. It was not possible to cancel the invitation.',
                },
            },
            invite_caregiver: {
                title: 'Invite caregiver',
                name_input: 'Name',
                contact_method_input: 'Contact method',
                email_input: 'Email address',
                phone_number_input: 'Mobile phone number',
                save_button: 'Send invitation',
                saving_button: 'Sending...',
                save_dialog_title: 'Send invitation to {{name}}?',
                save_dialog_message:
                    'When sending, {{name}} will receive an invitation to communicate and have access to some information of {{senior}}.',
                save_dialog_action_cancel_button: 'Cancel',
                save_dialog_action_save_button: 'Send',
                router_prompt_title: 'Exit without sending the invitation?',
                router_prompt_message: 'When exiting, you will lose all information entered in the invitation.',
                router_prompt_action_cancel_button: 'Cancel',
                router_prompt_action_ok_button: 'Yes, exit',
                invite_caregiver_cancel_toast: 'The invitation was cancelled.',
                success: {
                    save_success_button: 'Sent!',
                    invite_caregiver_success_message: 'The invitation was sent to {{name}}.',
                },
                errors: {
                    error_on_save_button: 'Error while sending',
                    invalid_on_save_button: 'There is invalid information',
                    form_validation_invalid_field: 'Fill in this field to complete the invitation',
                    form_validation_invalid_phone_number: 'The mobile phone number provided is not valid',
                    form_validation_invalid_email: 'The email address provided is not valid',
                },
            },
            applications: {
                search_input_placeholder: 'Search applications',
                name_header: 'Name',
                category_header: 'Category',
                state_header: 'State',
                state_value_true: 'Active',
                state_value_false: 'Inactive',
                no_applications: 'There are no applications.',
                enable_application_dialog_title: 'Enable the application {{name}}?',
                enable_application_dialog_message:
                    'By enabling the application, it will be available to the senior {{name}}.',
                enable_application_dialog_action_yes_button: 'Yes, enable',
                enable_application_dialog_action_no_button: 'Cancel',
                disable_application_dialog_title: 'Disable the application {{name}}?',
                disable_application_dialog_message:
                    'By disabling the application, it will no longer be available to the senior {{name}}.',
                disable_application_dialog_action_yes_button: 'Yes, disable',
                disable_application_dialog_action_no_button: 'Cancel',
                errors: {
                    enable_application_error_message:
                        'An error occurred. It was not possible to enable the application.',
                    disable_application_error_message:
                        'An error occurred. It was not possible to disable the application.',
                    update_state_application_error_message:
                        'An error occurred. It was not possible to update the state of the application.',
                },
            },
            devices: {
                identifier_header: 'Identifier',
                type_header: 'Type',
                state_header: 'State',
                state_value_true: 'Active',
                state_value_false: 'Inactive',
                dissociate_device_header: 'Dissociate device',
                no_devices: 'There are no devices.',
                associate_device_button: 'Associate device',
                dissociate_device_dialog_title: 'Dissociate the device with identifier "{{identifier}}" from {{name}}?',
                dissociate_device_dialog_message:
                    'By dissociating the device, {{name}} will no longer be able to use it to enjoy the Sioslife system.',
                dissociate_device_dialog_action_yes_button: 'Yes, dissociate',
                dissociate_device_dialog_action_no_button: 'Cancel',
                errors: {
                    dissociate_device_error_message: 'An error occurred. It was not possible to dissociate the device.',
                },
            },
            errors: {
                senior_does_not_exist_error: 'It was not possible to get the senior you tried to access.',
            },
        },
    },
    professional_caregivers: {
        root: {
            title: 'Caregivers',
            invite_button: 'Invite caregiver',
            search_input_placeholder: 'Search caregivers',
            name_header: 'Name',
            email_header: 'Email',
            affiliation_type_header: 'Type',
            state_header: 'State',
            state_value_true: 'Active',
            state_value_false: 'Inactive',
            no_professional_caregivers: 'There are no caregivers.',
        },
        edit: {
            tabs: {
                profile: 'Profile',
                affiliations: 'Affiliations',
            },
            errors: {
                professional_caregiver_does_not_exist_error:
                    'It was not possible to get the caregiver you tried to access.',
            },
        },
    },
    user_form: {
        title_create_senior: 'Add senior',
        title_edit_senior: 'Edit senior',
        title_current_user_profile: 'Profile',
        title_view_professional_caregiver_profile: 'Caregiver profile',
        delete_senior_button: 'Delete this profile',
        delete_senior_dialog_title: 'Delete profile of senior "{{name}}"?',
        delete_senior_dialog_content:
            "By deleting this profile, it will be permanently deleted from the entity's list of seniors.",
        delete_dialog_action_yes_button: 'Yes, delete',
        delete_dialog_action_no_button: 'Cancel',
        name_input: 'Name',
        state_input: 'State',
        state_value_true: 'Active',
        state_value_false: 'Inactive',
        gender_input: 'Gender',
        gender_placeholder_input: 'Select a gender',
        birthday_input: 'Birthday',
        birthday_placeholder_input: 'Select a date',
        address_input: 'Address',
        religion_input: 'Religion',
        religion_placeholder_input: 'Select a religion',
        occupation_input: 'Occupation',
        language_input: 'Language',
        language_placeholder_input: 'Select a language',
        region_input: 'Region',
        region_placeholder_input: 'Select a region',
        email_input: 'Email address',
        phone_number_input: 'Mobile phone number',
        tax_payer_number_input: 'Tax identification number',
        profile_picture_input: 'Profile Picture',
        id_input: 'Id',
        exit_button: 'Exit',
        save_button: 'Save',
        saving_button: 'Saving...',
        deleting_button: 'Deleting...',
        create_senior_dialog_title: 'Save creation of senior "{{name}}"?',
        create_senior_dialog_message: 'By saving, the new senior will be added to the seniors list.',
        save_senior_dialog_title: 'Save changes to the senior\'s profile of "{{name}}"?',
        save_senior_dialog_message: 'When saving, the senior will be updated with the newly entered information.',
        save_current_user_profile_dialog_title: 'Save changes to your profile?',
        save_current_user_profile_dialog_message:
            'When saving, your profile will be updated with the newly entered information.',
        save_dialog_action_cancel_button: 'Cancel',
        save_dialog_action_save_button: 'Save',
        senior_router_prompt_edit_title: 'Exit without saving changes to the senior\'s profile of "{{name}}"?',
        senior_router_prompt_create_title: 'Exit without saving senior creation of "{{name}}"?',
        senior_router_prompt_create_message:
            'When exiting, the senior will not be saved and all information entered in it will be lost.',
        senior_router_prompt_edit_message:
            'When exiting, you will lose all newly entered changes to the senior profile.',
        current_user_router_prompt_edit_title: 'Exit without saving changes to your profile?',
        current_user_router_prompt_edit_message:
            'When exiting, you will lose all newly entered changes to your profile.',
        router_prompt_action_cancel_button: 'Cancel',
        router_prompt_action_ok_button: 'Yes, exit',
        senior_create_cancel_toast: 'The creation of the new senior was canceled.',
        senior_edit_cancel_toast: 'The edition of the senior "{{name}}" was canceled.',
        current_user_edit_cancel_toast: 'The edition of your profile was canceled.',
        current_user_edit_email_not_allowed_tooltip_title: 'It is currently not possible to change your email address.',
        current_user_edit_state_not_allowed_tooltip_title: 'It is currently not possible to change your state.',
        success: {
            save_success_button: 'Saved!',
            delete_success_button: 'Deleted!',
            create_senior_success_message: 'The senior "{{name}}" was created with success!',
            edit_senior_success_message: "The changes on the senior's profile were saved.",
            edit_current_user_success_message: 'The changes on your profile were saved.',
            edit_current_user_app_language_to_chosen_language_success_message:
                "The app's language was updated to {{language}}.",
            edit_current_user_app_language_to_browser_language_success_message:
                "The app's language was updated to your browser language, {{language}}.",
            edit_current_user_app_language_to_default_language_success_message:
                "The app's language was updated to the default language, {{language}}.",
        },
        errors: {
            error_on_save_button: 'Error while saving',
            invalid_on_save_button: 'There is invalid information',
            error_on_delete_senior_button: 'Error while deleting the senior',
            senior_edit_form_validation_invalid_field: 'Fill in this field to complete editing the senior',
            senior_create_form_validation_invalid_field: 'Fill in this field to complete the creation of the senior',
            current_user_edit_form_validation_invalid_field: 'Fill in this field to complete editing your profile',
            form_validation_invalid_phone_number: 'The mobile phone number provided is not valid',
            form_validation_invalid_email: 'The email address provided is not valid',
            form_validation_invalid_tax_payer_number: 'The tax identification number provided is not valid',
            form_validation_invalid_name_maximum_characters: 'The name must have less than {{max}} characters',
        },
    },
    entity_services: {
        root: {
            title: 'Services',
            add_button: 'Add service',
            search_input_placeholder: 'Search services',
            name_header: 'Name',
            display_order_header: 'Order',
            state_header: 'State',
            state_value_true: 'Active',
            state_value_false: 'Inactive',
            no_services: 'There are no services configured.',
            deleted_service_dialog_title: 'Service "{{name}}" deleted',
            deleted_service_dialog_message1: '"{{name}}" was deleted.',
            deleted_service_dialog_message2:
                'The service is no longer available on the {{caregiversAppName}} application and on {{seniorsDeviceName}}.',
            deleted_service_dialog_ok_button: 'Ok',
        },
        edit: {
            title_create: 'Create service',
            title_edit: 'Edit service',
            exit_button: 'Exit',
            save_button: 'Save',
            saving_button: 'Saving...',
            title_input: 'Name',
            state_input: 'State',
            phone_number_input: 'Phone number',
            phone_number_use_general_number: 'Use general number',
            phone_number_use_specific_number: 'Use specific number',
            display_order_input: 'Order',
            description_input: 'Description',
            details_input: 'Details',
            attach_document_input: 'Add PDF document',
            document_informative_note_input: 'Message about contract information',
            icon_input: 'Icon',
            image_input: 'Image',
            button_preview: 'Button preview',
            state_value_true: 'Active',
            state_value_false: 'Inactive',
            number_input: 'Number',
            upload: 'Attach',
            remove_document: 'Remove',
            view_document: 'View',
            image_info: 'The image should have the type JPG/JPEG. For better results use the resolution of 292x288px.',
            delete_button: 'Delete service',
            deleting_button: 'Deleting...',
            delete_dialog_title: 'Delete service "{{name}}"?',
            delete_dialog_content:
                "By deleting this service, all users of the {{caregiversAppName}} application and {{seniorsDeviceName}} will lose access to it. In addition, the service will also be permanently deleted from the entity's list of services.",
            delete_dialog_action_yes_button: 'Yes, delete',
            delete_dialog_action_no_button: 'Cancel',
            router_prompt_edit_title: 'Exit without saving changes to service details of "{{name}}"?',
            router_prompt_create_title: 'Exit without saving service creation of "{{name}}"?',
            router_prompt_create_message:
                'When exiting, the service will not be saved and all information entered in it will be lost.',
            router_prompt_edit_message: 'When exiting, you will lose all newly entered changes to the service details.',
            router_prompt_action_cancel_button: 'Cancel',
            router_prompt_action_ok_button: 'Yes, exit',
            create_service_dialog_title: 'Save creation of service "{{name}}"?',
            create_service_dialog_message: 'By saving, the new service will be added to the services list.',
            save_service_dialog_title: 'Save changes to service details "{{name}}"?',
            save_service_dialog_message: 'When saving, the service will be updated with the newly entered information.',
            save_service_dialog_action_cancel_button: 'Cancel',
            save_service_dialog_action_save_button: 'Save',
            service_create_cancel_toast: 'The creation of the new service was canceled.',
            service_edit_cancel_toast: 'The edition of the service "{{name}}" was canceled.',
            errors: {
                error_on_save_button: 'Error while saving',
                invalid_on_save_button: 'There is invalid information',
                delete_service_fail_button: 'Error while deleting service',
                edit_form_validation_invalid_field: 'Fill in this field to complete editing the service',
                create_form_validation_invalid_field: 'Fill in this field to complete the creation of the service',
                create_form_validation_invalid_icon: 'Pick an icon to complete the creation of the service',
                create_form_validation_invalid_image: 'Upload an image to complete the creation of the service',
                form_validation_invalid_phone_number: 'The phone number provided is not valid',
                service_does_not_exist_error: 'It was not possible to get the service you tried to access.',
            },
            success: {
                save_success_button: 'Saved!',
                delete_success_button: 'Deleted!',
                save_success_message: 'The service "{{name}}" was created with success!',
                edit_success_message: "The changes on the service's details were saved.",
            },
        },
    },
    applications: {
        root: {
            title: 'Applications',
            search_input_placeholder: 'Search applications',
            name_header: 'Name',
            category_header: 'Category',
            state_header: 'State',
            state_value_true: 'Active',
            state_value_false: 'Inactive',
            no_applications: 'There are no applications.',
            enable_application_dialog_title: 'Enable the application {{name}}?',
            enable_application_dialog_message:
                'By enabling the application, it will be available to all seniors in the entity {{name}}.',
            enable_application_dialog_action_yes_button: 'Yes, enable',
            enable_application_dialog_action_no_button: 'Cancel',
            disable_application_dialog_title: 'Disable the application {{name}}?',
            disable_application_dialog_message:
                'By disabling the application, it will no longer be available to all seniors in the entity {{name}}.',
            disable_application_dialog_action_yes_button: 'Yes, disable',
            disable_application_dialog_action_no_button: 'Cancel',
            errors: {
                enable_application_error_message: 'An error occurred. It was not possible to enable the application.',
                disable_application_error_message: 'An error occurred. It was not possible to disable the application.',
                update_state_application_error_message:
                    'An error occurred. It was not possible to update the state of the application.',
            },
        },
        edit: {
            tabs: {
                details: 'Details',
                users: 'Users',
            },
            errors: {
                application_does_not_exist_error: 'It was not possible to get the application you tried to access.',
            },
        },
    },
    application_form: {
        name_input: 'Name',
        state_input: 'State',
        state_value_true: 'Active',
        state_value_false: 'Inactive',
        description_input: 'Description',
        is_system_application_input: 'System application',
        is_system_application_value_yes: 'Yes',
        is_system_application_value_no: 'No',
        available_for_user_types_input: 'Types of users for which the application is available',
        icon_input: 'Icon',
        picture_input: 'Picture',
        category_input: 'Category',
        activity_type_input: 'Activity type',
        windows_key_input: 'Windows key',
        android_key_input: 'Android key',
    },
    devices: {
        root: {
            title: 'Devices',
            search_input_placeholder: 'Search devices',
            identifier_header: 'Identifier',
            type_header: 'Type',
            state_header: 'State',
            state_value_true: 'Active',
            state_value_false: 'Inactive',
            no_devices: 'There are no devices.',
            add_button: 'Add device',
        },
        edit: {
            tabs: {
                details: 'Details',
                state: 'State',
            },
            errors: { device_does_not_exist_error: 'It was not possible to get the device you tried to access.' },
        },
    },
    section_tabs: { exit_button: 'Exit' },
    occupation_input: { no_options: 'No options', loading: 'Loading...' },
    user_menu: {
        logout: 'Logout',
        profile: 'Profile',
        errors: { logout_error_message: 'An error occurred. It was not possible to logout.' },
    },
    error_box: {
        title: 'Unable to perform the action.',
        description:
            'Sorry, there was an error trying to perform the action. Please, contact the Support team with the details provided.',
        details_button: 'See details',
    },
    country_select: {
        title: 'Country code',
        no_options: 'No options',
    },
    tablet_button: {
        empty_title: '(Button title)',
    },
    icons_picker: {
        title: 'Icons Picker',
        cancel_button: 'Cancel',
        confirm_button: 'Confirm',
    },
    image_cropper_dialog: {
        title: 'Prepare image',
        zoom: 'Zoom',
        rotation: 'Rotation',
        save_button: 'Save',
        cancel_button: 'Cancel',
    },
    loading_content: {
        loading: 'Loading...',
    },
    activity_card: {
        today: 'Today',
        yesterday: 'Yesterday',
        this_week: 'This week',
        this_month: 'This month',
        previous_month: 'Last month',
        past: 'Past',
        entity: 'Entity',
        device: 'Device',
        duration: 'Duration',
        end_type: 'End type',
        participants: 'Participants',
        application: 'Application',
        application_battle_with: 'with',
        level_on_app: 'Level {{level}} in the application',
        missed_call: 'tried to call but the call was not answered.',
        rejected_call: 'tried to call but the call was rejected.',
        call_started: 'called',
        drawing_sent: 'Drawing sent to',
        and: 'and',
        photo_sent: 'Photo sent to',
        camera_used: 'Camera used',
        response: 'Response',
        video_message: 'Video message sent by',
    },
    notification_card: {
        open_relationship_invite: 'Open invitation',
        relationship_invite: {
            message_sender: '{{sender}} sent you an invitation to add {{target}} to your contacts list.',
            message_unknown: 'You received an invitation to add {{target}} to your contacts list.',
        },
        user_signed_in: {
            message: '{{name}} signed in Sioslife.',
        },
        photo_sent: {
            message: '{{name}} shared a photo with you.',
        },
        drawing_sent: {
            message: '{{name}} shared a drawing with you.',
        },
        without_license: {
            message: '{{name}} does not have a license to use Sioslife systems.',
        },
        missed_call: {
            message: '{{name}} tried to call you but got no answer.',
        },
        video_message: {
            message: '{{name}} sent you a video message.',
        },
        sos_request: {
            message: 'SOS help request from {{name}}. Please, confirm that everything is fine.',
        },
        reminder_response: {
            message: '{{name}} {{response}} to the reminder "{{reminder}}".',
            responses: {
                positive: 'replied "Yes"',
                negative: 'replied "No"',
                not_responding: 'did not answer',
            },
        },
        relationship_invite_request_result: {
            message_accepted: '{{name}} accepted {{target}} as a contact.',
            message_declined: '{{name}} did not accept {{target}} as a contact.',
        },
        caregiver_administrator_role_request_result: {
            message_accepted: '{{name}} accepted {{target}} as Administrator.',
            message_declined: '{{name}} did not accept {{target}} as Administrator.',
        },
        relationship_role_change: {
            message_senior_myself: '{{name}} defined your profile as {{role}} of their Sioslife.',
            message_senior_other: '{{name}} defined {{target}} as {{role}} of their Sioslife.',
            message_caregiver_myself: "{{name}} defined your profile as {{role}} of {{senior}}'s Sioslife.",
            message_caregiver_other: "{{name}} defined {{target}} as {{role}} of {{senior}}'s Sioslife.",
            message_unknown_myself: "Your profile was defined as {{role}} of {{senior}}'s Sioslife.",
            message_unknown_other: "{{target}} was defined as {{role}} of {{senior}}'s Sioslife.",
        },
        personal_data_sharing_preferences_change: {
            message: '{{name}} changed the shared data settings of their Sioslife.',
            current_data_settings_title: 'The current settings are:',
            can_access_profile_data: 'Profile data',
            can_access_contacts: 'Contacts',
            can_access_applications: 'Applications',
            can_access_multimedia: 'Multimedia',
            can_access_reminders: 'Reminders',
            can_access_activity_feed: 'Activity feed',
        },
        relationship_end: {
            message_myself:
                '{{name}} removed your profile from their contacts list. From now on you will not be able to communicate through Sioslife products.',
            message_other:
                '{{name}} removed {{target}} from their contacts list. From now on {{target}} will not be able to communicate with {{name}} through Sioslife products.',
        },
    },
    section_list: {
        refresh: 'Refresh',
        no_more_data: 'No more data',
        without_data: 'There are no data',
        today: 'Today',
        old_f: 'Old',
        old_m: 'Old',
    },
    activity_timeline: { title: 'Activity' },
    clipboard_text_field: {
        copy_to_clipboard_button: 'Copy',
        copy_to_clipboard_tooltip_message: 'Copy to clipboard',
        success: { copy_to_clipboard_success_message: 'Text copied to clipboard!' },
    },
    activity_end_types: {
        [ActivityEndType.UserAction]: 'User action',
        [ActivityEndType.SystemTimeout]: 'System timeout',
    },
    device_types: {
        [DeviceType.Handset]: 'Handset',
        [DeviceType.Tablet]: 'Tablet',
        [DeviceType.Desktop]: 'Desktop',
        [DeviceType.Laptop]: 'Laptop',
        [DeviceType.Unknown]: 'Unknown',
    },
    camera_facing: {
        [CameraFacing.Front]: 'Front camera',
        [CameraFacing.Rear]: 'Rear camera',
        [CameraFacing.External]: 'External camera',
        [CameraFacing.Unknown]: 'Unknown camera',
    },
    reminder_response_values: {
        [ReminderResponseValue.Positive]: 'Yes / OK',
        [ReminderResponseValue.Negative]: 'No',
        [ReminderResponseValue.NotResponding]: 'Did not responded',
    },
    invite_states: {
        [InviteState.Accepted]: 'Accepted',
        [InviteState.Cancelled]: 'Cancelled',
        [InviteState.Open]: 'Opened',
        [InviteState.Pending]: 'Pending',
        [InviteState.Refused]: 'Refused',
        [InviteState.RefusedBySenior]: 'Refused by the senior',
        [InviteState.WaitingForSeniorApproval]: 'Waiting for the senior approval',
        [InviteState.AcceptedBySenior]: 'Accepted by the senior',
        [InviteState.Expired]: 'Expired',
    },
    process_statuses: {
        [ProcessStatus.Done]: 'Done',
        [ProcessStatus.InProgress]: 'In progress',
        [ProcessStatus.ToDo]: 'To do',
        [ProcessStatus.Undefined]: 'Undefined',
    },
};
