/**
 * Activity End Type
 */
export enum ActivityEndType {
    /**
     * It was ended by a user action.
     */
    UserAction = 'user-action',

    /**
     * It was ended by the system as timeout action.
     */
    SystemTimeout = 'system-timeout',
}
