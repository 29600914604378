export enum CaregiverRelationshipRole {
    /**
     * Administrator
     *
     * Can manage all aspects of an Senior service.
     */
    Administrator = 'administrator',

    /**
     * Member
     *
     * Has read access to all Senior info.
     */
    Member = 'member',

    /**
     * Contact
     *
     * Only accesses Senior Public and Basic Info. Can also communicate.
     */
    Contact = 'contact',
}
