/**
 * Language Codes
 */
export enum LanguageCode {
    /**
     * Portuguese
     */
    pt = 'pt',

    /**
     * English
     */
    en = 'en',

    /**
     * Spanish
     */
    es = 'es',
}
