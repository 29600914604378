import { ActivityEndType } from '../backend/enums/activity-end-type';
import { AffiliationType } from '../backend/enums/affiliation-type';
import { BackendLanguageTag } from '../backend/enums/backend-language-tag';
import { CameraFacing } from '../backend/enums/camera-facing';
import { CaregiverRelationshipRole } from '../backend/enums/caregiver-relationship-role';
import { DeviceType } from '../backend/enums/device-type';
import { InviteState } from '../backend/enums/invite-state';
import { ProcessStatus } from '../backend/enums/process-status';
import { RegionCode } from '../backend/enums/region-code';
import { RelationshipType } from '../backend/enums/relationship-type';
import { ReminderResponseValue } from '../backend/enums/reminder-response-value';
import { UserGender } from '../backend/enums/user-gender';
import { UserType } from '../backend/enums/user-type';
import { SignInError } from '../enums/sign-in-error';
import { LanguageTranslation } from './types';

export const es_ES: LanguageTranslation = {
    legal_documents: {
        terms_and_conditions: 'Términos y Condiciones',
        privacy_policy: 'Política de Privacidad',
    },
    products: {
        bluebird: 'Sioslife',
        bluebird_with_gender: 'a Sioslife',
    },
    relationship_types: {
        [RelationshipType.Family]: 'Familiar',
        [RelationshipType.Friend]: 'Amigo',
        [RelationshipType.Healthcare]: 'Profesional asistencial',
        [RelationshipType.Service]: 'Servicio',
        [RelationshipType.Other]: 'Otro',
    },
    user_types: {
        [UserType.Caregiver]: 'Cuidador',
        [UserType.Follower]: 'Seguidor',
        [UserType.Senior]: 'Mayor',
    },
    genders: {
        [UserGender.Female]: 'Mujer',
        [UserGender.Male]: 'Hombre',
        [UserGender.Undisclosed]: 'No revelado',
        [UserGender.Unspecified]: 'Otro',
    },
    languages: {
        [BackendLanguageTag.ptPT]: 'Portugués (Portugal)',
        [BackendLanguageTag.enUS]: 'Inglés (EUA)',
        [BackendLanguageTag.esES]: 'Español (España)',
    },
    regions: { [RegionCode.PT]: 'Portugal', [RegionCode.ES]: 'España' },
    roles: {
        [CaregiverRelationshipRole.Administrator]: 'Administrador',
        [CaregiverRelationshipRole.Contact]: 'Contacto',
        [CaregiverRelationshipRole.Member]: 'Miembro',
    },
    religions: {
        african_traditional_and_diasporic: 'Tradicional y diáspora africana',
        agnostic: 'Agnosticismo',
        animism: 'Animismo',
        atheist: 'Ateísmo',
        baha_i: 'Bahaísmo',
        buddhism: 'Budismo',
        cao_dai: 'Caodaísmo',
        chinese_traditional: 'Tradicional china',
        christianity: 'Cristianismo',
        hinduism: 'Hinduismo',
        islam: 'Islamismo',
        jainism: 'Jainismo',
        juche: 'Juche',
        judaism: 'Judaísmo',
        neo_paganism: 'Neopaganismo',
        nonreligious: 'Sem religión',
        rastafari: 'Rastafari',
        secular: 'Secularismo',
        shinto: 'Sintoísmo',
        sikhism: 'Sijismo',
        spiritism: 'Espiritismo',
        tenrikyo: 'Tenrikyō',
        unitarian_universalism: 'Unitarismo universalista',
        zoroastrianism: 'Zoroastrismo',
        primal_indigenous: 'Indígena',
        undisclosed: 'No revelado',
        unspecified: 'Otro',
    },
    affiliation_types: {
        [AffiliationType.Senior]: 'Mayor',
        [AffiliationType.Administrator]: 'Administrador',
        [AffiliationType.Manager]: 'Manager',
        [AffiliationType.Operator]: 'Operador',
        [AffiliationType.MedicalProfessional]: 'Profesional de salud',
    },
    date_time_picker: {
        cancel_button: 'Cancelar',
        confirm_button: 'Confirmar',
    },
    login: {
        welcome: '¡Hola cuidador!',
        welcome_accept_invite: '¡Acceda a su cuenta para aceptar la invitación!',
        instruction: 'Ingrese sus credenciales para comenzar.',
        email_input: 'Dirección de e-mail',
        password_input: 'Contraseña',
        enter_button: 'Acceder',
        forgot_password: '¿Ha olvidado su contraseña?',
        validate_invite: '¿Ha sido invitado para ser cuidador?',
        use_invite_code: 'Utilizar código de invitación',
        login_blocked_instruction:
            'Descarga e instala la aplicación {{appName}} para poder comunicarte con tus amigos y familiares y disfrutar de todas las funcionalidades.',
        google_play_store_button: 'Descargar Android',
        apple_store_button: 'Descargar iOS',
        errors: {
            invalid_password: 'Contraseña no válida',
            [SignInError.Unknown]: 'Se ha producido un error. Por favor, inténtelo más tarde.',
            [SignInError.InvalidEmail]: 'E-mail no válido',
            [SignInError.UserDisabled]: 'Usuario desactivado',
            [SignInError.UserNotFound]: 'Usuario no encontrado',
            [SignInError.WrongPassword]: 'Contraseña incorrecta',
            [SignInError.FailedToGetMe]:
                'No se pudo cargar la información del usuario. Por favor, entre en contacto con el servicio de Atención al Cliente.',
        },
    },
    recover_password: {
        title: '¿Ha olvidado su contraseña?',
        subtitle: '¡Nosotros le ayudamos!',
        instructions:
            'Para poder definir una nueva contraseña, indique por favor su correo electrónico. Le enviaremos un mensaje con un enlace que le permitirá realizar el cambio.',
        email_input: 'Dirección de e-mail',
        send_button: 'Enviar',
        password_remembered: '¡Acabo de recordar mi contraseña!',
        go_back_button: 'Volver a la pantalla de acceso',
        errors: {
            invalid_email: 'E-mail no válido',
            send_request_error_message: 'Se ha producido un error. No ha sido posible realizar la solicitud.',
        },
        success: {
            send_request_success_message:
                'Se han enviado las instrucciones para alterar su contraseña al correo electrónico que nos ha indicado.',
        },
    },
    replace_password: {
        title: 'Definir una nueva contraseña',
        instructions:
            '¡Vale! ¡Buen trabajo! Vamos a definir una nueva contraseña. Escriba su nueva contraseña en los dos espacios a continuación para finalizar el proceso.',
        new_password_input: 'Nueva contraseña',
        repeat_password_input: 'Repetir la nueva contraseña',
        submit_button: 'Confirmar',
        password_remembered: '¡Acabo de recordar mi contraseña!',
        go_back_button: 'Ir a la pantalla de acceso',
        errors: {
            invalid_password_minimum_characters: 'La contraseña debe tener al menos 6 caracteres',
            invalid_password_blank_spaces: 'La contraseña no puede empezar ni terminar con espacios en blanco',
            invalid_repeat_password_empty: 'Repita la contraseña',
            invalid_repeat_password_mismatch: 'Por favor, comprueba que las contraseñas son iguales',
            change_password_error_message: 'Se ha producido un error. No ha sido posible definir su nueva contraseña.',
        },
        success: {
            change_password_success_message:
                'Se ha definido la contraseña. Ahora puede entrar con su nueva contraseña.',
        },
    },
    validate_invite: {
        title: 'Utilizar código de invitación',
        instructions_1: 'Para ser cuidador {{brand}} deberá contar con una invitación de acceso a la plataforma.',
        instructions_2:
            'Si ya ha solicitado una invitación, por favor compruebe su buzón de email y/o sus mensajes de SMS y busque un mensaje {{brand}}. En ese mensaje encontrará el código que deberá indicar abajo.',
        code_input: 'Código de la invitación',
        send_button: 'Validar invitación y avanzar',
        errors: {
            invalid_code: 'Código inválido',
            invalid_invite_error_message:
                'La invitación que ha intentado usar está invalidada. Por favor, verifique si ha indicado el código correcto. En caso afirmativo, solicite una nueva invitación.',
        },
    },
    accept_relationship_invite: {
        title: 'Aceptar la invitación para ser cuidador de',
        instructions:
            'Para ser cuidador deberá tener una cuenta de acceso a la plataforma {{brand}}. Si es la primera vez que está aceptando una invitación, pulse en "{{button_create}}". Si ya ha aceptado otras invitaciones anteriormente, use su cuenta existente y pulse en "{{button_login}}".',
        create_account_button: 'Aceptar y crear nueva cuenta',
        login_button: 'Entrar en una cuenta ya existente',
        errors: {
            invalid_invite_error_message:
                'La invitación que ha intentado usar está invalidada. Por favor, solicite una nueva invitación.',
        },
    },
    notifications: {
        date: 'Fecha',
        time: 'Hora',
        title: 'Notificaciones',
        exit_button: 'Salir',
        read_all_notifications_normal_button: 'Marcar todas como leídas',
        read_all_notifications_loading_button: 'Leyendo...',
        read_all_notifications_success_button: '¡Notificaciones leídas!',
        read_all_notifications_error_button: 'Error al leer notificaciones',
        read_all_notifications_dialog_title: '¿Marcar todas las notificaciones como leídas?',
        read_all_notifications_dialog_content:
            'Podrá volver a marcar individualmente cada notificación como no leída posteriormente, si así lo desea.',
        read_all_notifications_dialog_action_yes_button: 'Sí, marcar',
        read_all_notifications_dialog_action_no_button: 'Cancelar',
        read_all_notifications_disabled_tooltip_message: 'Ya has marcado todas las notificaciones como leídas.',
        success: {
            read_all_notifications_success_message: 'Todas las notificaciones se marcaron como leídas.',
        },
    },
    push_notifications: {
        warnings: {
            unsubscribed_generic_warning_title: 'No puedo recibir notificaciones en tiempo real',
            unsubscribed_not_supported_warning_message:
                'El navegador que está utilizando no admite la recepción de notificaciones. Utilice Chrome, Firefox o Edge para recibir notificaciones en tiempo real.',
            unsubscribed_error_warning_message:
                'Ha ocurrido un error por lo que no puede recibir notificaciones en tiempo real.',
            unsubscribed_permissions_denied_warning_message:
                'Se han denegado los permisos para recibir notificaciones. Por favor, habilite las notificaciones para el navegador que está utilizando.',
        },
    },
    relationship_invite_modal: {
        title: 'Aceptar la invitación para ser cuidador',
        relationship_type_input: '¿Qué tipo de relación que le vincula a {{name}}?',
        alert_description:
            'Está usando una cuenta con información diferente a la información disponible en esta invitación. Por favor, revise con atención si desea continuar.',
        accept_button: 'Sí, acepto',
        decline_button: 'No, rechazo',
        relationship_already_exists_description:
            'Ya es cuidador por haber aceptado una invitación con anterioridad. No necesita hacer nada más.',
        invite_done_used_description: 'La invitación ya ha sido usada. No necesita hacer nada más.',
        invite_done_refused_description: 'La invitación ya ha sido rechazada. No necesita hacer nada más.',
        invite_done_accepted_description: 'La invitación ya se ha aceptado. No necesita hacer nada más.',
        loading_invite_description_1: 'Cargando detalles de la invitación...',
        loading_invite_description_2: 'Por favor, espere...',
        errors: {
            refuse_invite_error_message: 'Se ha producido un error. No ha sido posible rechazar la invitación.',
            accept_invite_error_message: 'Se ha producido un error. No ha sido posible aceptar la invitación.',
            fetch_relationship_invite_error_message:
                'Se ha producido un error. No ha sido posible abrir la invitación.',
            fetch_relationship_invite_invalid_error_message: 'La invitación que ha intentado abrir está invalidada.',
        },
        success: {
            refuse_invite_success_message:
                'La invitación ha sido rechazada. {{name}} no forma parte de su lista de contactos.',
            accept_invite_success_message:
                'Se ha aceptado la invitación. {{name}} forma ahora parte de su lista de contactos. ',
        },
    },
    settings: {
        title: 'Ajustes',
        exit_button: 'Salir',
        personal_information_section_title: 'Mi perfil',
        change_theme_section_title: 'Mi tema',
        legal_information_section_title: 'Información legal',
        relationships_section_title: 'Mis relaciones',
        affiliations_section_title: 'Mis afiliaciones',
        logout_normal_button: 'Terminar sesión',
        logout_loading_button: 'Terminando sesión...',
        logout_error_button: 'Error al terminar sesión',
        theme_input: 'Tema',
        dark_option: 'Oscuro',
        light_option: 'Claro',
        system_option: 'Tema del sistema (automático)',
    },
    create_informal_caregiver_account: {
        register_title: 'Registro',
        essential_information_step_description:
            '¡Bienvenido a la plataforma {{brand}}! Vamos a crear una cuenta de usuario para que pueda convertirse en cuidador y terminar así el proceso de aceptación de la invitación que ha recibido.',
        essential_information_step: 'Información esencial',
        personal_information_step: 'Información personal',
        optional_information_step: 'Información opcional',
        note_title: 'Nota',
        note_statement:
            'Los datos indicados con "*" son obligatorios para la creación de la cuenta de acceso para el cuidador.',
        continue_button: 'Continuar',
        create_account_button: 'Aceptar invitación y crear cuenta',
        start_using_button: 'Empezar a usar',
        go_back_to_login_button: 'Ir a la pantalla de acceso',
        go_back_button: 'Voltar para trás',
        success_title: '¡Listo!',
        success_description:
            '¡Enhorabuena! ¡Ahora ya es usuario de la plataforma {{brand}}! Gracias por unirse a esta comunidad. Ahora puede avanzar a su cuenta de usuario.',
        error_title: '¡No ha sido posible crear la cuenta de usuario!',
        error_description:
            'Lamentablemente ha habido algún fallo al crear su cuenta de usuario. Por favor, entre en contacto con el servicio de Atención al Cliente para obtener ayuda.',
        relationship_type_input: 'Tipo de relación con {{name}}',
        email_address_input: 'Dirección de e-mail',
        password_input: 'Contraseña',
        repeat_password_input: 'Repetir la contraseña',
        legal_documents_title: 'Documentos legales',
        legal_documents_statement:
            'Confirmo que he leído y acepto las <0>Condiciones Generales de Uso</0> y la <1>Política de Privacidad y Protección de Datos</1>.',
        name_input: 'Nombre',
        mobile_number_input: 'Número de móvil',
        profile_picture_input: 'Foto de perfil',
        gender_input: 'Género',
        gender_placeholder_input: 'Seleccione un género',
        language_input: 'Idioma',
        language_placeholder_input: 'Seleccione un idioma',
        birthday_input: 'Fecha de nacimiento',
        birthday_input_placeholder: 'Seleccione una fecha',
        tax_identification_number_input: 'Número de identificación fiscal',
        errors: {
            invalid_email: 'E-mail no válido',
            invalid_password_minimum_characters: 'La contraseña debe tener al menos 6 caracteres',
            invalid_password_blank_spaces: 'La contraseña no puede empezar ni terminar con espacios en blanco',
            invalid_repeat_password_empty: 'Repita la contraseña',
            invalid_repeat_password_mismatch: 'Por favor, comprueba que las contraseñas son iguales',
            invalid_name: 'Nombre no válido',
            invalid_name_maximum_characters: 'El nombre debe tener menos de {{max}} caracteres',
            invalid_mobile_phone_number: 'Número de móvil no válido',
            invalid_tax_identification_number: 'Número de identificación fiscal no válido',
            empty_mobile_phone_number: 'Falta el número de móvil',
            legal_documents_not_accepted: 'Es obligatorio aceptar los documentos legales',
            enter_account_error_message: 'Se ha producido un error. No ha sido posible entrar en su cuenta.',
            invalid_invite_error_message:
                'La invitación que ha intentado usar para la creación de una cuenta está invalidada.',
        },
    },
    office: {
        profile_type_tabs: {
            informal_caregiver: 'Informal',
            professional_caregiver: 'Profesional',
            system_administrator: 'Admin. de Sistema',
            partner: 'Compañero',
        },
        informal_caregiver: {
            tabs: {
                activity: 'Actividad',
                profile: 'Perfil',
                media: 'Multimedia',
                reminders: 'Recordatorios',
            },
            add_menu: {
                button: 'Añadir',
                title: '¿Qué desea añadir?',
                create_reminder: 'Recordatorio',
                send_photo: 'Fotografía',
                send_video: 'Vídeo',
                send_invite: 'Invitación para el cuidador',
            },
            relationship_picker: {
                caption: 'Estás viendo el perfil de:',
                dialog_title: 'Seleccionar otro contacto',
            },
        },
        professional_caregiver: {
            tabs: {
                start: 'Comienzo',
                seniors: 'Mayores',
                applications: 'Aplicaciones',
                services: 'Servicios',
                professional_caregivers: 'Cuidadores',
                activity: 'Actividad',
                devices: 'Dispositivos',
            },
            affiliation_picker: {
                caption: 'Estás a trabajar la entidad:',
                dialog_title: 'Seleccionar otra entidad',
            },
        },
        system_administrator: {
            tabs: {
                start: 'Comienzo',
                analytics: 'Analítica',
                activity: 'Actividad',
                devices: 'Dispositivos',
                apiDocs: 'Doc. de API',
                applications: 'Aplicaciones',
                entities: 'Entidades',
            },
        },
        partner: {
            tabs: {
                start: 'Comienzo',
                apiDocs: 'Doc. de API',
            },
        },
        legacy_integration: {
            entity_services_disabled_alert_title: 'Incapaz de realizar la acción.',
            entity_services_disabled_alert_message:
                'Lo sentimos, no tienes acceso a esta área. Por favor, entre en contacto con el servicio de Atención al Cliente para obtener más información.',
        },
    },
    seniors: {
        root: {
            title: 'Mayores',
            add_button: 'Añadir mayor',
            search_input_placeholder: 'Buscar mayores',
            name_header: 'Nombre',
            gender_header: 'Género',
            state_header: 'Estado',
            state_value_true: 'Activo',
            state_value_false: 'Inactivo',
            no_seniors: 'No hay mayores.',
            deleted_senior_dialog_title: 'Perfil del mayor "{{name}}" eliminado',
            deleted_senior_dialog_message: 'El perfil del mayor ya no existe en la lista de mayored de la entidad.',
            deleted_senior_dialog_ok_button: 'Ok',
        },
        edit: {
            tabs: {
                profile: 'Perfil',
                caregivers: 'Cuidadores',
                applications: 'Aplicaciones',
                devices: 'Dispositivos',
                activity: 'Actividade',
                analytics: 'Estadísticas',
                cards: 'Tarjetas',
                health: 'Salud',
            },
            caregivers: {
                invite_caregiver_button: 'Invitar cuidador',
                invites_history_button: 'Ver invitaciones',
                no_caregivers: 'No hay cuidadores.',
                name_header: 'Nombre',
                relationship_type_header: 'Tipo',
                remove_relationship_header: 'Eliminar relación',
                remove_relationship_dialog_title:
                    '¿Eliminar la relación entre el mayor {{senior}} y el cuidador {{caregiver}}?',
                remove_relationship_dialog_message:
                    'Al eliminar esta relación, el mayor {{senior}} ya no podrá comunicarse con el cuidador {{caregiver}}.',
                remove_relationship_dialog_action_yes_button: 'Sí, eliminar',
                remove_relationship_dialog_action_no_button: 'Cancelar',
                errors: {
                    remove_relationship_error_message:
                        'Se ha producido un error. No ha sido posible eliminar la relación.',
                },
            },
            invites_history: {
                title: 'Historial de invitaciones',
                no_invites: 'No hay invitaciones.',
                name_header: 'Nombre',
                contact_header: 'Contacto',
                state_header: 'Estado de la invitación',
                status_header: 'Estado del proceso',
                cancel_invite_dialog_title: '¿Cancelar la invitación para {{caregiver}} ser {{senior}} cuidador?',
                cancel_invite_dialog_message:
                    'Al cancelar esta invitación, el cuidador {{caregiver}} no podrá aceptar la invitación para comunicarse con el mayor {{senior}}.',
                cancel_invite_dialog_action_yes_button: 'Sí, cancelar',
                cancel_invite_dialog_action_no_button: 'No cambiar la invitación',
                errors: {
                    cancel_invite_error_message: 'Se ha producido un error. No ha sido posible cancelar la invitación.',
                },
            },
            invite_caregiver: {
                title: 'Invitar cuidador',
                name_input: 'Nombre',
                contact_method_input: 'Medio de contacto',
                email_input: 'Dirección de e-mail',
                phone_number_input: 'Número de móvil',
                save_button: 'Enviar invitación',
                saving_button: 'Enviando...',
                save_dialog_title: '¿Enviar invitación a {{name}}?',
                save_dialog_message:
                    'Al enviar, {{name}} recibirá una invitación para comunicarse y tener acceso a cierta información de {{senior}}.',
                save_dialog_action_cancel_button: 'Cancelar',
                save_dialog_action_save_button: 'Enviar',
                router_prompt_title: '¿Salir sin enviar la invitación?',
                router_prompt_message: 'Al salir, perderá toda la información introducida en la invitación.',
                router_prompt_action_cancel_button: 'Cancelar',
                router_prompt_action_ok_button: 'Sí, salir',
                invite_caregiver_cancel_toast: 'La invitación ha sido cancelada.',
                success: {
                    save_success_button: '¡Enviado!',
                    invite_caregiver_success_message: 'La invitación ha sido enviada a {{name}}.',
                },
                errors: {
                    error_on_save_button: 'Error al enviar',
                    invalid_on_save_button: 'Hay información inválida',
                    form_validation_invalid_field: 'Complete este campo para completar la invitación',
                    form_validation_invalid_phone_number: 'El número de móvil proporcionado no es válido',
                    form_validation_invalid_email: 'El dirección de e-mail proporcionado no es válido',
                },
            },
            applications: {
                search_input_placeholder: 'Buscar aplicaciones',
                name_header: 'Nombre',
                category_header: 'Categoría',
                state_header: 'Estado',
                state_value_true: 'Activo',
                state_value_false: 'Inactivo',
                no_applications: 'No hay aplicaciones.',
                enable_application_dialog_title: '¿Activar la aplicación {{name}}?',
                enable_application_dialog_message:
                    'Al activar la aplicación, estará disponible para el mayor {{name}}.',
                enable_application_dialog_action_yes_button: 'Sí, activar',
                enable_application_dialog_action_no_button: 'Cancelar',
                disable_application_dialog_title: '¿Desactivar la aplicación {{name}}?',
                disable_application_dialog_message:
                    'Al desactivar la aplicación, ya no estará disponible para el mayor {{name}}.',
                disable_application_dialog_action_yes_button: 'Sí, desactivar',
                disable_application_dialog_action_no_button: 'Cancelar',
                errors: {
                    enable_application_error_message:
                        'Se ha producido un error. No ha sido posible activar la aplicación.',
                    disable_application_error_message:
                        'Se ha producido un error. No ha sido posible desactivar la aplicación.',
                    update_state_application_error_message:
                        'Se ha producido un error. No ha sido posible actualizar el estado de la aplicación.',
                },
            },
            devices: {
                identifier_header: 'Identificador',
                type_header: 'Tipo',
                state_header: 'Estado',
                state_value_true: 'Activo',
                state_value_false: 'Inactivo',
                dissociate_device_header: 'Desasociar dispositivo',
                no_devices: 'No hay dispositivos.',
                associate_device_button: 'Asociar dispositivo',
                dissociate_device_dialog_title:
                    '¿Desasociar el dispositivo con identificador "{{identifier}}" de {{name}}?',
                dissociate_device_dialog_message:
                    'Al desasociar el dispositivo, {{name}} ya no podrá usarlo para disfrutar del sistema Sioslife.',
                dissociate_device_dialog_action_yes_button: 'Sim, desasociar',
                dissociate_device_dialog_action_no_button: 'Cancelar',
                errors: {
                    dissociate_device_error_message:
                        'Se ha producido un error. No ha sido posible desasociar el dispositivo.',
                },
            },
            errors: {
                senior_does_not_exist_error: 'No se pudo obtener el mayor al que intentó acceder.',
            },
        },
    },
    professional_caregivers: {
        root: {
            title: 'Cuidadores',
            invite_button: 'Invitar cuidador',
            search_input_placeholder: 'Buscar cuidadores',
            name_header: 'Nombre',
            email_header: 'E-mail',
            affiliation_type_header: 'Tipo',
            state_header: 'Estado',
            state_value_true: 'Activo',
            state_value_false: 'Inactivo',
            no_professional_caregivers: 'No hay cuidadores.',
        },
        edit: {
            tabs: {
                profile: 'Perfil',
                affiliations: 'Afiliaciones',
            },
            errors: {
                professional_caregiver_does_not_exist_error: 'No se pudo obtener el cuidador al que intentó acceder.',
            },
        },
    },
    user_form: {
        title_create_senior: 'Añadir mayor',
        title_edit_senior: 'Editar mayor',
        title_current_user_profile: 'Perfil',
        title_view_professional_caregiver_profile: 'Perfil del cuidador',
        delete_senior_button: 'Eliminar este perfil',
        delete_senior_dialog_title: '¿Eliminar perfil del mayor "{{name}}"?',
        delete_senior_dialog_content:
            'Al eliminar este perfil, se eliminará definitivamente de la lista de mayores de la entidad.',
        delete_dialog_action_yes_button: 'Sí, eliminar',
        delete_dialog_action_no_button: 'Cancelar',
        name_input: 'Nombre',
        state_input: 'Estado',
        state_value_true: 'Activo',
        state_value_false: 'Inactivo',
        gender_input: 'Género',
        gender_placeholder_input: 'Seleccione un género',
        birthday_input: 'Fecha de nacimiento',
        birthday_placeholder_input: 'Seleccione una fecha',
        address_input: 'Dirección',
        religion_input: 'Religión',
        religion_placeholder_input: 'Seleccione una religión',
        occupation_input: 'Profesión',
        language_input: 'Idioma',
        language_placeholder_input: 'Seleccione un idioma',
        region_input: 'Región',
        region_placeholder_input: 'Seleccione una región',
        email_input: 'Dirección de e-mail',
        phone_number_input: 'Número de móvil',
        tax_payer_number_input: 'Número de identificación fiscal',
        profile_picture_input: 'Foto de perfil',
        id_input: 'Id',
        exit_button: 'Salir',
        save_button: 'Salvar',
        saving_button: 'Salvando...',
        deleting_button: 'Eliminando...',
        create_senior_dialog_title: '¿Salvar la creación del mayor "{{name}}"?',
        create_senior_dialog_message: 'Al salvar, el nuevo mayor se añadirá a la lista de mayores.',
        save_senior_dialog_title: '¿Salvar cambios en el perfil del mayor "{{name}}"?',
        save_senior_dialog_message: 'Al salvar, el mayor se actualizará con la información recién ingresada.',
        save_current_user_profile_dialog_title: '¿Salvar cambios en su perfil?',
        save_current_user_profile_dialog_message:
            'Al salvar, su perfil se actualizará con la información recién ingresada.',
        save_dialog_action_cancel_button: 'Cancelar',
        save_dialog_action_save_button: 'Salvar',
        senior_router_prompt_edit_title: '¿Salir sin guardar los cambios en el perfil del mayor de "{{name}}"?',
        senior_router_prompt_create_title: '¿Salir sin guardar creación del mayor "{{name}}"?',
        senior_router_prompt_create_message:
            'Al salir, el mayor no se guardará y se perderá toda la información introducida en el mismo.',
        senior_router_prompt_edit_message:
            'Al salir, perderá todos los cambios introducidos recientemente en el perfil del mayor.',
        current_user_router_prompt_edit_title: '¿Salir sin guardar los cambios en su perfil?',
        current_user_router_prompt_edit_message:
            'Al salir, perderá todos los cambios introducidos recientemente en su perfil.',
        router_prompt_action_cancel_button: 'Cancelar',
        router_prompt_action_ok_button: 'Sí, salir',
        senior_create_cancel_toast: 'Se canceló la creación del nuevo mayor.',
        senior_edit_cancel_toast: 'Se canceló la edición del mayor "{{name}}".',
        current_user_edit_cancel_toast: 'Se canceló la edición de su perfil.',
        current_user_edit_email_not_allowed_tooltip_title: 'Actualmente no es posible cambiar su dirección de e-mail.',
        current_user_edit_state_not_allowed_tooltip_title: 'Actualmente no es posible cambiar su estado.',
        success: {
            save_success_button: '¡Salvado!',
            delete_success_button: '¡Eliminado!',
            create_senior_success_message: '¡El mayor "{{name}}" se creó con éxito!',
            edit_senior_success_message: 'Se guardaron los cambios en el perfil del mayor.',
            edit_current_user_success_message: 'Se guardaron los cambios en su perfil.',
            edit_current_user_app_language_to_chosen_language_success_message:
                'El idioma de la aplicación se ha actualizado a {{language}}.',
            edit_current_user_app_language_to_browser_language_success_message:
                'El idioma de la aplicación se ha actualizado al idioma de su navegador, el {{language}}.',
            edit_current_user_app_language_to_default_language_success_message:
                'El idioma de la aplicación se ha actualizado al idioma usado por defecto, el {{language}}.',
        },
        errors: {
            error_on_save_button: 'Error al salvar',
            invalid_on_save_button: 'Hay información inválida',
            error_on_delete_senior_button: 'Error al eliminar el mayor',
            senior_edit_form_validation_invalid_field: 'Complete este campo para completar la edición del mayor',
            senior_create_form_validation_invalid_field: 'Complete este campo para completar la creación del mayor',
            current_user_edit_form_validation_invalid_field:
                'Complete este campo para completar la edición de su perfil',
            form_validation_invalid_phone_number: 'El número de móvil proporcionado no es válido',
            form_validation_invalid_email: 'El dirección de e-mail proporcionado no es válido',
            form_validation_invalid_tax_payer_number: 'El número de identificación fiscal proporcionado no es válido',
            form_validation_invalid_name_maximum_characters: 'El nombre debe tener menos de {{max}} caracteres',
        },
    },
    entity_services: {
        root: {
            title: 'Servicios',
            add_button: 'Añadir servicio',
            search_input_placeholder: 'Buscar servicios',
            name_header: 'Nombre',
            display_order_header: 'Orden',
            state_header: 'Estado',
            state_value_true: 'Activo',
            state_value_false: 'Inactivo',
            no_services: 'No hay servicios configurados.',
            deleted_service_dialog_title: 'Servicio "{{name}}" eliminado',
            deleted_service_dialog_message1: '"{{name}}" fue eliminado.',
            deleted_service_dialog_message2:
                'El servicio ya no está disponible en la aplicación {{caregiversAppName}} y en {{seniorsDeviceName}}.',
            deleted_service_dialog_ok_button: 'Ok',
        },
        edit: {
            title_create: 'Crear servicio',
            title_edit: 'Editar servicio',
            exit_button: 'Salir',
            save_button: 'Salvar',
            saving_button: 'Salvando...',
            title_input: 'Nombre',
            state_input: 'Estado',
            phone_number_input: 'Número de teléfono',
            phone_number_use_general_number: 'Usar número general',
            phone_number_use_specific_number: 'Usar número específico',
            display_order_input: 'Orden',
            description_input: 'Descripción',
            details_input: 'Detalles',
            attach_document_input: 'Añadir documento PDF',
            document_informative_note_input: 'Mensaje sobre información del contrato',
            icon_input: 'Icono',
            image_input: 'Imagen',
            button_preview: 'Vista previa del botón',
            state_value_true: 'Activo',
            state_value_false: 'Inactivo',
            number_input: 'Número',
            upload: 'Adjuntar',
            remove_document: 'Eliminar',
            view_document: 'Vista',
            image_info:
                'La imagen debe tener el tipo JPG/JPEG. Para obtener mejores resultados, utilice la resolución de 292x288px.',
            delete_button: 'Eliminar servicio',
            deleting_button: 'Eliminando...',
            delete_dialog_title: '¿Eliminar servicio "{{name}}"?',
            delete_dialog_content:
                'Al eliminar este servicio, todos los usuarios de la aplicación {{caregiversAppName}} y {{seniorsDeviceName}} perderán el acceso a él. Además, el servicio también se eliminará definitivamente de la lista de servicios de la entidad.',
            delete_dialog_action_yes_button: 'Sí, eliminar',
            delete_dialog_action_no_button: 'Cancelar',
            router_prompt_edit_title: '¿Salir sin guardar los cambios en los detalles del servicio de "{{name}}"?',
            router_prompt_create_title: '¿Salir sin guardar creación del servicio "{{name}}"?',
            router_prompt_create_message:
                'Al salir, el servicio no se guardará y se perderá toda la información introducida en el mismo.',
            router_prompt_edit_message:
                'Al salir, perderá todos los cambios introducidos recientemente en los detalles del servicio.',
            router_prompt_action_cancel_button: 'Cancelar',
            router_prompt_action_ok_button: 'Sí, salir',
            create_service_dialog_title: '¿Salvar la creación del servicio "{{name}}"?',
            create_service_dialog_message: 'Al salvar, el nuevo servicio se añadirá a la lista de servicios.',
            save_service_dialog_title: '¿Salvar cambios en los detalles del servicio "{{name}}"?',
            save_service_dialog_message: 'Al salvar, el servicio se actualizará con la información recién ingresada.',
            save_service_dialog_action_cancel_button: 'Cancelar',
            save_service_dialog_action_save_button: 'Salvar',
            service_create_cancel_toast: 'Se canceló la creación del nuevo servicio.',
            service_edit_cancel_toast: 'Se canceló la edición del servicio "{{name}}".',
            errors: {
                error_on_save_button: 'Error al salvar',
                invalid_on_save_button: 'Hay información inválida',
                delete_service_fail_button: 'Error al eliminar el servicio',
                edit_form_validation_invalid_field: 'Complete este campo para completar la edición del servicio',
                create_form_validation_invalid_field: 'Complete este campo para completar la creación del servicio',
                create_form_validation_invalid_icon: 'Elija un icono para completar la creación del servicio',
                create_form_validation_invalid_image: 'Sube una imagen para completar la creación del servicio',
                form_validation_invalid_phone_number: 'El número de teléfono proporcionado no es válido',
                service_does_not_exist_error: 'No se pudo obtener el servicio al que intentó acceder.',
            },
            success: {
                save_success_button: '¡Salvado!',
                delete_success_button: '¡Eliminado!',
                save_success_message: '¡El servicio "{{name}}" se creó con éxito!',
                edit_success_message: 'Se guardaron los cambios en los detalles del servicio.',
            },
        },
    },
    applications: {
        root: {
            title: 'Aplicaciones',
            search_input_placeholder: 'Buscar aplicaciones',
            name_header: 'Nombre',
            category_header: 'Categoría',
            state_header: 'Estado',
            state_value_true: 'Activo',
            state_value_false: 'Inactivo',
            no_applications: 'No hay aplicaciones.',
            enable_application_dialog_title: '¿Activar la aplicación {{name}}?',
            enable_application_dialog_message:
                'Al activar la aplicación, estará disponible para todos los mayores de la entidad {{name}}.',
            enable_application_dialog_action_yes_button: 'Sí, activar',
            enable_application_dialog_action_no_button: 'Cancelar',
            disable_application_dialog_title: '¿Desactivar la aplicación {{name}}?',
            disable_application_dialog_message:
                'Al desactivar la aplicación, ya no estará disponible para todos los mayores de la entidad {{name}}.',
            disable_application_dialog_action_yes_button: 'Sí, desactivar',
            disable_application_dialog_action_no_button: 'Cancelar',
            errors: {
                enable_application_error_message: 'Se ha producido un error. No ha sido posible activar la aplicación.',
                disable_application_error_message:
                    'Se ha producido un error. No ha sido posible desactivar la aplicación.',
                update_state_application_error_message:
                    'Se ha producido un error. No ha sido posible actualizar el estado de la aplicación.',
            },
        },
        edit: {
            tabs: {
                details: 'Detalles',
                users: 'Usuarios',
            },
            errors: {
                application_does_not_exist_error: 'No se pudo obtener la aplicación al que intentó acceder.',
            },
        },
    },
    application_form: {
        name_input: 'Nombre',
        state_input: 'Estado',
        state_value_true: 'Activo',
        state_value_false: 'Inactivo',
        description_input: 'Descripción',
        is_system_application_input: 'Aplicación del sistema',
        is_system_application_value_yes: 'Sí',
        is_system_application_value_no: 'No',
        available_for_user_types_input: 'Tipos de usuarios para los que está disponible la aplicación',
        icon_input: 'Icono',
        picture_input: 'Imagen',
        category_input: 'Categoría',
        activity_type_input: 'Tipo de actividad',
        windows_key_input: 'Llave Windows',
        android_key_input: 'Llave Android',
    },
    devices: {
        root: {
            title: 'Dispositivos',
            search_input_placeholder: 'Buscar dispositivos',
            identifier_header: 'Identificador',
            type_header: 'Tipo',
            state_header: 'Estado',
            state_value_true: 'Activo',
            state_value_false: 'Inactivo',
            no_devices: 'No hay dispositivos.',
            add_button: 'Añadir dispositivo',
        },
        edit: {
            tabs: {
                details: 'Detalles',
                state: 'Estado',
            },
            errors: { device_does_not_exist_error: 'No se pudo obtener el dispositivo al que intentó acceder.' },
        },
    },
    section_tabs: { exit_button: 'Salir' },
    occupation_input: { no_options: 'Sin opciones', loading: 'Subiendo...' },
    user_menu: {
        logout: 'Terminar sesión',
        profile: 'Perfil',
        errors: { logout_error_message: 'Se ha producido un error. No ha sido posible terminar la sesión.' },
    },
    error_box: {
        title: 'Incapaz de realizar la acción.',
        description:
            'Lo sentimos, hubo un error al intentar realizar la acción. Por favor, entre en contacto con el servicio de Atención al Cliente con los detalles proporcionados.',
        details_button: 'Ver detalles',
    },
    country_select: {
        title: 'Prefijo país',
        no_options: 'Sin opciones',
    },
    tablet_button: {
        empty_title: '(Título del botón)',
    },
    icons_picker: {
        title: 'Selector de iconos',
        cancel_button: 'Cancelar',
        confirm_button: 'Confirmar',
    },
    image_cropper_dialog: {
        title: 'Preparar imagen',
        zoom: 'Zoom',
        rotation: 'Rotación',
        save_button: 'Salvar',
        cancel_button: 'Cancelar',
    },
    loading_content: {
        loading: 'Subiendo...',
    },
    activity_card: {
        today: 'Hoy dia',
        yesterday: 'Ayer',
        this_week: 'Esta semana',
        this_month: 'Este mes',
        previous_month: 'En el mes pasado',
        past: 'Pasado',
        entity: 'Entidad',
        device: 'Dispositivo',
        duration: 'Duración',
        end_type: 'Tipo final',
        participants: 'Las participantes',
        application: 'Aplicación',
        application_battle_with: 'con',
        level_on_app: 'Nivel {{level}} en la aplicación',
        missed_call: 'trató de llamar pero la llamada no fue respondida.',
        rejected_call: 'intentó llamar pero la llamada fue rechazada.',
        call_started: 'llamó a',
        drawing_sent: 'Dibujo enviado a',
        and: 'y',
        photo_sent: 'Foto enviada a',
        camera_used: 'Cámara usada',
        response: 'Respuesta',
        video_message: 'Videomensaje enviado por',
    },
    notification_card: {
        open_relationship_invite: 'Abrir invitación',
        relationship_invite: {
            message_sender: '{{sender}} le ha enviado una invitación para añadir a {{target}} a su lista de contactos.',
            message_unknown: 'Ha recibido una invitación para añadir a {{target}} a su lista de contactos.',
        },
        user_signed_in: {
            message: '{{name}} ha iniciado su sesión en Sioslife.',
        },
        photo_sent: {
            message: '{{name}} ha compartido una fotografía con usted.',
        },
        drawing_sent: {
            message: '{{name}} ha compartido un dibujo contigo.',
        },
        without_license: {
            message: '{{name}} no tiene licencia para usar los sistemas Sioslife.',
        },
        missed_call: {
            message: '{{name}} ha intentado llamarle pero no ha obtenido respuesta.',
        },
        video_message: {
            message: '{{name}} le ha enviado un mensaje de vídeo.',
        },
        sos_request: {
            message: 'Solicitud de ayuda SOS de {{name}}. Por favor, confirme si está bien.',
        },
        reminder_response: {
            message: '{{name}} {{response}} al recordatorio "{{reminder}}".',
            responses: {
                positive: 'ha respondido "Sí"',
                negative: 'ha respondido "No"',
                not_responding: 'no ha respondido',
            },
        },
        relationship_invite_request_result: {
            message_accepted: '{{name}} ha aceptado a {{target}} como contacto.',
            message_declined: '{{name}} no ha aceptado a {{target}} como contacto.',
        },
        caregiver_administrator_role_request_result: {
            message_accepted: '{{name}} ha aceptado {{target}} como Administrador.',
            message_declined: '{{name}} no ha aceptado {{target}} como Administrador.',
        },
        relationship_role_change: {
            message_senior_myself: '{{name}} ha definido su perfil como {{role}} de su Sioslife.',
            message_senior_other: '{{name}} ha definido {{target}} como {{role}} de su Sioslife.',
            message_caregiver_myself: '{{name}} ha definido su perfil como {{role}} del Sioslife de {{senior}}.',
            message_caregiver_other: '{{name}} ha definido {{target}} como {{role}} del Sioslife de {{senior}}.',
            message_unknown_myself: 'Su perfil ha sido definido como {{role}} del Sioslife de {{senior}}.',
            message_unknown_other: '{{target}} ha sido definido como {{role}} del Sioslife de {{senior}}.',
        },
        personal_data_sharing_preferences_change: {
            message: '{{name}} ha alterado las definiciones para compartir datos de su Sioslife.',
            current_data_settings_title: 'Las definiciones actuales son:',
            can_access_profile_data: 'Información de perfil',
            can_access_contacts: 'Contactos',
            can_access_applications: 'Aplicaciones',
            can_access_multimedia: 'Multimedia',
            can_access_reminders: 'Recordatorios',
            can_access_activity_feed: 'Registro de actividad',
        },
        relationship_end: {
            message_myself:
                '{{name}} ha eliminado su perfil de su lista de contactos. A partir de este punto no van a conseguir comunicar a través los sistemas Sioslife.',
            message_other:
                '{{name}} ha eliminado {{target}} de su lista de contactos. A partir de aquí {{target}} no va a conseguir entrar en contacto con {{name}} a través los sistemas Sioslife.',
        },
    },
    section_list: {
        refresh: 'Actualizar',
        no_more_data: 'No hay más datos',
        without_data: 'No hay datos',
        today: 'Hoy',
        old_f: 'Más antiguas',
        old_m: 'Más antiguos',
    },
    activity_timeline: { title: 'Actividad' },
    clipboard_text_field: {
        copy_to_clipboard_button: 'Copiar',
        copy_to_clipboard_tooltip_message: 'Copiar en el portapapeles',
        success: { copy_to_clipboard_success_message: '¡Texto copiado en el portapapeles!' },
    },
    activity_end_types: {
        [ActivityEndType.UserAction]: 'Acción del usuario',
        [ActivityEndType.SystemTimeout]: 'Tiempo de espera del sistema',
    },
    device_types: {
        [DeviceType.Handset]: 'Teléfono móvil',
        [DeviceType.Tablet]: 'Tablet',
        [DeviceType.Desktop]: 'Computadora',
        [DeviceType.Laptop]: 'Ordenador portátil',
        [DeviceType.Unknown]: 'Desconocido',
    },
    camera_facing: {
        [CameraFacing.Front]: 'Cámara frontal',
        [CameraFacing.Rear]: 'Cámara trasera',
        [CameraFacing.External]: 'Cámara externa',
        [CameraFacing.Unknown]: 'Cámara desconocida',
    },
    reminder_response_values: {
        [ReminderResponseValue.Positive]: 'Sí / Está bien',
        [ReminderResponseValue.Negative]: 'No',
        [ReminderResponseValue.NotResponding]: 'No respondio',
    },
    invite_states: {
        [InviteState.Accepted]: 'Aceptada',
        [InviteState.Cancelled]: 'Cancelada',
        [InviteState.Open]: 'Abierta',
        [InviteState.Pending]: 'Pendiente',
        [InviteState.Refused]: 'Rechazada',
        [InviteState.RefusedBySenior]: 'Rechazada por el mayor',
        [InviteState.WaitingForSeniorApproval]: 'Pendiente de aprobación por parte del senior',
        [InviteState.AcceptedBySenior]: 'Aceptada por el mayor',
        [InviteState.Expired]: 'Caducada',
    },
    process_statuses: {
        [ProcessStatus.Done]: 'Concluido',
        [ProcessStatus.InProgress]: 'Em progreso',
        [ProcessStatus.ToDo]: 'Para hacer',
        [ProcessStatus.Undefined]: 'No definido',
    },
};
