/**
 * Relationship Type
 */
export enum RelationshipType {
    /**
     * Family
     */
    Family = 'family',

    /**
     * Friend
     */
    Friend = 'friend',

    /**
     * Healthcare
     */
    Healthcare = 'healthcare',

    /**
     * Service
     */
    Service = 'service',

    /**
     * Other
     */
    Other = 'other',
}
