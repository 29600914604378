/**
 * The camera facing
 */
export enum CameraFacing {
    /**
     * It was not possible to identify the camera location.
     */
    Unknown = 'unknown',

    /**
     * The lens facing the user on the same side as the screen.
     */
    Front = 'front',

    /**
     * The lens facing away from the user, just like a regular digital camera.
     */
    Rear = 'rear',

    /**
     * An external single camera device was used.
     */
    External = 'external',
}
