/**
 * User Gender
 */
export enum UserGender {
    /**
     * Male
     */
    Male = 'male',

    /**
     * Female
     */
    Female = 'female',

    /**
     * Unspecified (Other)
     */
    Unspecified = 'unspecified',

    /**
     * Undisclosed (I prefer not to say)
     */
    Undisclosed = 'undisclosed',
}
