import { Resource } from 'i18next';
import { ActivityEndType } from '../backend/enums/activity-end-type';
import { AffiliationType } from '../backend/enums/affiliation-type';
import { BackendLanguageTag } from '../backend/enums/backend-language-tag';
import { CameraFacing } from '../backend/enums/camera-facing';
import { CaregiverRelationshipRole } from '../backend/enums/caregiver-relationship-role';
import { DeviceType } from '../backend/enums/device-type';
import { InviteState } from '../backend/enums/invite-state';
import { ProcessStatus } from '../backend/enums/process-status';
import { RegionCode } from '../backend/enums/region-code';
import { RelationshipType } from '../backend/enums/relationship-type';
import { ReminderResponseValue } from '../backend/enums/reminder-response-value';
import { UserGender } from '../backend/enums/user-gender';
import { UserType } from '../backend/enums/user-type';
import { SignInError } from '../enums/sign-in-error';

/*
 * Language Tags (for the App)
 */
export enum AppLanguageTag {
    /*
     * Portuguese from Portugal
     */
    ptPT = 'pt_PT',

    /*
     * English from US
     */
    enUS = 'en_US',

    /*
     * Spanish from Spain
     */
    esES = 'es_ES',
}

export type LanguagesResource = Resource & {
    [language in AppLanguageTag]: LanguageResource;
};

export interface LanguageResource {
    translation: LanguageTranslation;
}

export interface LanguageTranslation {
    legal_documents: {
        terms_and_conditions: string;
        privacy_policy: string;
    };
    products: {
        bluebird: string;
        bluebird_with_gender: string;
    };
    relationship_types: {
        [RelationshipType.Family]: string;
        [RelationshipType.Friend]: string;
        [RelationshipType.Healthcare]: string;
        [RelationshipType.Service]: string;
        [RelationshipType.Other]: string;
    };
    user_types: {
        [UserType.Caregiver]: string;
        [UserType.Follower]: string;
        [UserType.Senior]: string;
    };
    genders: {
        [UserGender.Female]: string;
        [UserGender.Male]: string;
        [UserGender.Undisclosed]: string;
        [UserGender.Unspecified]: string;
    };
    languages: {
        [BackendLanguageTag.ptPT]: string;
        [BackendLanguageTag.enUS]: string;
        [BackendLanguageTag.esES]: string;
    };
    regions: { [RegionCode.PT]: string; [RegionCode.ES]: string };
    roles: {
        [CaregiverRelationshipRole.Administrator]: string;
        [CaregiverRelationshipRole.Contact]: string;
        [CaregiverRelationshipRole.Member]: string;
    };
    religions: {
        african_traditional_and_diasporic: string;
        agnostic: string;
        animism: string;
        atheist: string;
        baha_i: string;
        buddhism: string;
        cao_dai: string;
        chinese_traditional: string;
        christianity: string;
        hinduism: string;
        islam: string;
        jainism: string;
        juche: string;
        judaism: string;
        neo_paganism: string;
        nonreligious: string;
        rastafari: string;
        secular: string;
        shinto: string;
        sikhism: string;
        spiritism: string;
        tenrikyo: string;
        unitarian_universalism: string;
        zoroastrianism: string;
        primal_indigenous: string;
        undisclosed: string;
        unspecified: string;
    };
    affiliation_types: {
        [AffiliationType.Senior]: string;
        [AffiliationType.Administrator]: string;
        [AffiliationType.Manager]: string;
        [AffiliationType.Operator]: string;
        [AffiliationType.MedicalProfessional]: string;
    };
    date_time_picker: {
        cancel_button: string;
        confirm_button: string;
    };
    login: {
        welcome: string;
        welcome_accept_invite: string;
        instruction: string;
        email_input: string;
        password_input: string;
        enter_button: string;
        forgot_password: string;
        validate_invite: string;
        use_invite_code: string;
        login_blocked_instruction: string;
        google_play_store_button: string;
        apple_store_button: string;
        errors: {
            invalid_password: string;
            [SignInError.Unknown]: string;
            [SignInError.InvalidEmail]: string;
            [SignInError.UserDisabled]: string;
            [SignInError.UserNotFound]: string;
            [SignInError.WrongPassword]: string;
            [SignInError.FailedToGetMe]: string;
        };
    };
    recover_password: {
        title: string;
        subtitle: string;
        instructions: string;
        email_input: string;
        send_button: string;
        password_remembered: string;
        go_back_button: string;
        errors: {
            invalid_email: string;
            send_request_error_message: string;
        };
        success: {
            send_request_success_message: string;
        };
    };
    replace_password: {
        title: string;
        instructions: string;
        new_password_input: string;
        repeat_password_input: string;
        submit_button: string;
        password_remembered: string;
        go_back_button: string;
        errors: {
            invalid_password_minimum_characters: string;
            invalid_password_blank_spaces: string;
            invalid_repeat_password_empty: string;
            invalid_repeat_password_mismatch: string;
            change_password_error_message: string;
        };
        success: {
            change_password_success_message: string;
        };
    };
    validate_invite: {
        title: string;
        instructions_1: string;
        instructions_2: string;
        code_input: string;
        send_button: string;
        errors: {
            invalid_code: string;
            invalid_invite_error_message: string;
        };
    };
    accept_relationship_invite: {
        title: string;
        instructions: string;
        create_account_button: string;
        login_button: string;
        errors: {
            invalid_invite_error_message: string;
        };
    };
    notifications: {
        date: string;
        time: string;
        title: string;
        exit_button: string;
        read_all_notifications_normal_button: string;
        read_all_notifications_loading_button: string;
        read_all_notifications_success_button: string;
        read_all_notifications_error_button: string;
        read_all_notifications_dialog_title: string;
        read_all_notifications_dialog_content: string;
        read_all_notifications_dialog_action_yes_button: string;
        read_all_notifications_dialog_action_no_button: string;
        read_all_notifications_disabled_tooltip_message: string;
        success: {
            read_all_notifications_success_message: string;
        };
    };
    push_notifications: {
        warnings: {
            unsubscribed_generic_warning_title: string;
            unsubscribed_not_supported_warning_message: string;
            unsubscribed_error_warning_message: string;
            unsubscribed_permissions_denied_warning_message: string;
        };
    };
    relationship_invite_modal: {
        title: string;
        relationship_type_input: string;
        alert_description: string;
        accept_button: string;
        decline_button: string;
        relationship_already_exists_description: string;
        invite_done_used_description: string;
        invite_done_refused_description: string;
        invite_done_accepted_description: string;
        loading_invite_description_1: string;
        loading_invite_description_2: string;
        errors: {
            refuse_invite_error_message: string;
            accept_invite_error_message: string;
            fetch_relationship_invite_error_message: string;
            fetch_relationship_invite_invalid_error_message: string;
        };
        success: {
            refuse_invite_success_message: string;
            accept_invite_success_message: string;
        };
    };
    settings: {
        title: string;
        exit_button: string;
        personal_information_section_title: string;
        change_theme_section_title: string;
        legal_information_section_title: string;
        relationships_section_title: string;
        affiliations_section_title: string;
        logout_normal_button: string;
        logout_loading_button: string;
        logout_error_button: string;
        theme_input: string;
        dark_option: string;
        light_option: string;
        system_option: string;
    };
    create_informal_caregiver_account: {
        register_title: string;
        essential_information_step_description: string;
        essential_information_step: string;
        personal_information_step: string;
        optional_information_step: string;
        note_title: string;
        note_statement: string;
        continue_button: string;
        create_account_button: string;
        start_using_button: string;
        go_back_to_login_button: string;
        go_back_button: string;
        success_title: string;
        success_description: string;
        error_title: string;
        error_description: string;
        relationship_type_input: string;
        email_address_input: string;
        password_input: string;
        repeat_password_input: string;
        legal_documents_title: string;
        legal_documents_statement: string;
        name_input: string;
        mobile_number_input: string;
        profile_picture_input: string;
        gender_input: string;
        gender_placeholder_input: string;
        language_input: string;
        language_placeholder_input: string;
        birthday_input: string;
        birthday_input_placeholder: string;
        tax_identification_number_input: string;
        errors: {
            invalid_email: string;
            invalid_password_minimum_characters: string;
            invalid_password_blank_spaces: string;
            invalid_repeat_password_empty: string;
            invalid_repeat_password_mismatch: string;
            invalid_name: string;
            invalid_name_maximum_characters: string;
            invalid_mobile_phone_number: string;
            invalid_tax_identification_number: string;
            empty_mobile_phone_number: string;
            legal_documents_not_accepted: string;
            enter_account_error_message: string;
            invalid_invite_error_message: string;
        };
    };
    office: {
        profile_type_tabs: {
            informal_caregiver: string;
            professional_caregiver: string;
            system_administrator: string;
            partner: string;
        };
        informal_caregiver: {
            tabs: {
                activity: string;
                profile: string;
                media: string;
                reminders: string;
            };
            add_menu: {
                button: string;
                title: string;
                create_reminder: string;
                send_photo: string;
                send_video: string;
                send_invite: string;
            };
            relationship_picker: {
                caption: string;
                dialog_title: string;
            };
        };
        professional_caregiver: {
            tabs: {
                start: string;
                seniors: string;
                applications: string;
                services: string;
                professional_caregivers: string;
                activity: string;
                devices: string;
            };
            affiliation_picker: {
                caption: string;
                dialog_title: string;
            };
        };
        system_administrator: {
            tabs: {
                start: string;
                analytics: string;
                activity: string;
                devices: string;
                apiDocs: string;
                applications: string;
                entities: string;
            };
        };
        partner: {
            tabs: {
                start: string;
                apiDocs: string;
            };
        };
        legacy_integration: {
            entity_services_disabled_alert_title: string;
            entity_services_disabled_alert_message: string;
        };
    };
    seniors: {
        root: {
            title: string;
            add_button: string;
            search_input_placeholder: string;
            name_header: string;
            gender_header: string;
            state_header: string;
            state_value_true: string;
            state_value_false: string;
            no_seniors: string;
            deleted_senior_dialog_title: string;
            deleted_senior_dialog_message: string;
            deleted_senior_dialog_ok_button: string;
        };
        edit: {
            tabs: {
                profile: string;
                caregivers: string;
                applications: string;
                devices: string;
                activity: string;
                analytics: string;
                cards: string;
                health: string;
            };
            caregivers: {
                invite_caregiver_button: string;
                invites_history_button: string;
                no_caregivers: string;
                name_header: string;
                relationship_type_header: string;
                remove_relationship_header: string;
                remove_relationship_dialog_title: string;
                remove_relationship_dialog_message: string;
                remove_relationship_dialog_action_yes_button: string;
                remove_relationship_dialog_action_no_button: string;
                errors: {
                    remove_relationship_error_message: string;
                };
            };
            invites_history: {
                title: string;
                no_invites: string;
                name_header: string;
                contact_header: string;
                state_header: string;
                status_header: string;
                cancel_invite_dialog_title: string;
                cancel_invite_dialog_message: string;
                cancel_invite_dialog_action_yes_button: string;
                cancel_invite_dialog_action_no_button: string;
                errors: {
                    cancel_invite_error_message: string;
                };
            };
            invite_caregiver: {
                title: string;
                name_input: string;
                contact_method_input: string;
                email_input: string;
                phone_number_input: string;
                save_button: string;
                saving_button: string;
                save_dialog_title: string;
                save_dialog_message: string;
                save_dialog_action_cancel_button: string;
                save_dialog_action_save_button: string;
                router_prompt_title: string;
                router_prompt_message: string;
                router_prompt_action_cancel_button: string;
                router_prompt_action_ok_button: string;
                invite_caregiver_cancel_toast: string;
                success: { save_success_button: string; invite_caregiver_success_message: string };
                errors: {
                    error_on_save_button: string;
                    invalid_on_save_button: string;
                    form_validation_invalid_field: string;
                    form_validation_invalid_phone_number: string;
                    form_validation_invalid_email: string;
                };
            };
            applications: {
                search_input_placeholder: string;
                name_header: string;
                category_header: string;
                state_header: string;
                state_value_true: string;
                state_value_false: string;
                no_applications: string;
                enable_application_dialog_title: string;
                enable_application_dialog_message: string;
                enable_application_dialog_action_yes_button: string;
                enable_application_dialog_action_no_button: string;
                disable_application_dialog_title: string;
                disable_application_dialog_message: string;
                disable_application_dialog_action_yes_button: string;
                disable_application_dialog_action_no_button: string;
                errors: {
                    enable_application_error_message: string;
                    disable_application_error_message: string;
                    update_state_application_error_message: string;
                };
            };
            devices: {
                identifier_header: string;
                type_header: string;
                state_header: string;
                state_value_true: string;
                state_value_false: string;
                dissociate_device_header: string;
                no_devices: string;
                associate_device_button: string;
                dissociate_device_dialog_title: string;
                dissociate_device_dialog_message: string;
                dissociate_device_dialog_action_yes_button: string;
                dissociate_device_dialog_action_no_button: string;
                errors: {
                    dissociate_device_error_message: string;
                };
            };
            errors: { senior_does_not_exist_error: string };
        };
    };
    professional_caregivers: {
        root: {
            title: string;
            invite_button: string;
            search_input_placeholder: string;
            name_header: string;
            email_header: string;
            affiliation_type_header: string;
            state_header: string;
            state_value_true: string;
            state_value_false: string;
            no_professional_caregivers: string;
        };
        edit: {
            tabs: {
                profile: string;
                affiliations: string;
            };
            errors: { professional_caregiver_does_not_exist_error: string };
        };
    };
    user_form: {
        title_create_senior: string;
        title_edit_senior: string;
        title_current_user_profile: string;
        title_view_professional_caregiver_profile: string;
        delete_senior_button: string;
        delete_senior_dialog_title: string;
        delete_senior_dialog_content: string;
        delete_dialog_action_yes_button: string;
        delete_dialog_action_no_button: string;
        name_input: string;
        state_input: string;
        state_value_true: string;
        state_value_false: string;
        gender_input: string;
        gender_placeholder_input: string;
        birthday_input: string;
        birthday_placeholder_input: string;
        address_input: string;
        religion_input: string;
        religion_placeholder_input: string;
        occupation_input: string;
        language_input: string;
        language_placeholder_input: string;
        region_input: string;
        region_placeholder_input: string;
        email_input: string;
        phone_number_input: string;
        tax_payer_number_input: string;
        profile_picture_input: string;
        id_input: string;
        exit_button: string;
        save_button: string;
        saving_button: string;
        deleting_button: string;
        create_senior_dialog_title: string;
        create_senior_dialog_message: string;
        save_senior_dialog_title: string;
        save_senior_dialog_message: string;
        save_current_user_profile_dialog_title: string;
        save_current_user_profile_dialog_message: string;
        save_dialog_action_cancel_button: string;
        save_dialog_action_save_button: string;
        senior_router_prompt_edit_title: string;
        senior_router_prompt_create_title: string;
        senior_router_prompt_create_message: string;
        senior_router_prompt_edit_message: string;
        current_user_router_prompt_edit_title: string;
        current_user_router_prompt_edit_message: string;
        router_prompt_action_cancel_button: string;
        router_prompt_action_ok_button: string;
        senior_create_cancel_toast: string;
        senior_edit_cancel_toast: string;
        current_user_edit_cancel_toast: string;
        current_user_edit_email_not_allowed_tooltip_title: string;
        current_user_edit_state_not_allowed_tooltip_title: string;
        success: {
            save_success_button: string;
            delete_success_button: string;
            create_senior_success_message: string;
            edit_senior_success_message: string;
            edit_current_user_success_message: string;
            edit_current_user_app_language_to_chosen_language_success_message: string;
            edit_current_user_app_language_to_browser_language_success_message: string;
            edit_current_user_app_language_to_default_language_success_message: string;
        };
        errors: {
            error_on_save_button: string;
            invalid_on_save_button: string;
            error_on_delete_senior_button: string;
            senior_edit_form_validation_invalid_field: string;
            senior_create_form_validation_invalid_field: string;
            current_user_edit_form_validation_invalid_field: string;
            form_validation_invalid_phone_number: string;
            form_validation_invalid_email: string;
            form_validation_invalid_tax_payer_number: string;
            form_validation_invalid_name_maximum_characters: string;
        };
    };
    entity_services: {
        root: {
            title: string;
            add_button: string;
            search_input_placeholder: string;
            name_header: string;
            display_order_header: string;
            state_header: string;
            state_value_true: string;
            state_value_false: string;
            no_services: string;
            deleted_service_dialog_title: string;
            deleted_service_dialog_message1: string;
            deleted_service_dialog_message2: string;
            deleted_service_dialog_ok_button: string;
        };
        edit: {
            title_create: string;
            title_edit: string;
            exit_button: string;
            save_button: string;
            saving_button: string;
            title_input: string;
            state_input: string;
            phone_number_input: string;
            phone_number_use_general_number: string;
            phone_number_use_specific_number: string;
            display_order_input: string;
            description_input: string;
            details_input: string;
            attach_document_input: string;
            document_informative_note_input: string;
            icon_input: string;
            image_input: string;
            button_preview: string;
            state_value_true: string;
            state_value_false: string;
            number_input: string;
            upload: string;
            remove_document: string;
            view_document: string;
            image_info: string;
            delete_button: string;
            deleting_button: string;
            delete_dialog_title: string;
            delete_dialog_content: string;
            delete_dialog_action_yes_button: string;
            delete_dialog_action_no_button: string;
            router_prompt_edit_title: string;
            router_prompt_create_title: string;
            router_prompt_create_message: string;
            router_prompt_edit_message: string;
            router_prompt_action_cancel_button: string;
            router_prompt_action_ok_button: string;
            create_service_dialog_title: string;
            create_service_dialog_message: string;
            save_service_dialog_title: string;
            save_service_dialog_message: string;
            save_service_dialog_action_cancel_button: string;
            save_service_dialog_action_save_button: string;
            service_create_cancel_toast: string;
            service_edit_cancel_toast: string;
            errors: {
                error_on_save_button: string;
                invalid_on_save_button: string;
                delete_service_fail_button: string;
                edit_form_validation_invalid_field: string;
                create_form_validation_invalid_field: string;
                create_form_validation_invalid_icon: string;
                create_form_validation_invalid_image: string;
                form_validation_invalid_phone_number: string;
                service_does_not_exist_error: string;
            };
            success: {
                save_success_button: string;
                delete_success_button: string;
                save_success_message: string;
                edit_success_message: string;
            };
        };
    };
    applications: {
        root: {
            title: string;
            search_input_placeholder: string;
            name_header: string;
            category_header: string;
            state_header: string;
            state_value_true: string;
            state_value_false: string;
            no_applications: string;
            enable_application_dialog_title: string;
            enable_application_dialog_message: string;
            enable_application_dialog_action_yes_button: string;
            enable_application_dialog_action_no_button: string;
            disable_application_dialog_title: string;
            disable_application_dialog_message: string;
            disable_application_dialog_action_yes_button: string;
            disable_application_dialog_action_no_button: string;
            errors: {
                enable_application_error_message: string;
                disable_application_error_message: string;
                update_state_application_error_message: string;
            };
        };
        edit: {
            tabs: {
                details: string;
                users: string;
            };
            errors: { application_does_not_exist_error: string };
        };
    };
    application_form: {
        name_input: string;
        state_input: string;
        state_value_true: string;
        state_value_false: string;
        description_input: string;
        is_system_application_input: string;
        is_system_application_value_yes: string;
        is_system_application_value_no: string;
        available_for_user_types_input: string;
        icon_input: string;
        picture_input: string;
        category_input: string;
        activity_type_input: string;
        windows_key_input: string;
        android_key_input: string;
    };
    devices: {
        root: {
            title: string;
            search_input_placeholder: string;
            identifier_header: string;
            type_header: string;
            state_header: string;
            state_value_true: string;
            state_value_false: string;
            no_devices: string;
            add_button: string;
        };
        edit: {
            tabs: {
                details: string;
                state: string;
            };
            errors: { device_does_not_exist_error: string };
        };
    };
    section_tabs: { exit_button: string };
    occupation_input: { no_options: string; loading: string };
    user_menu: {
        logout: string;
        profile: string;
        errors: { logout_error_message: string };
    };
    error_box: {
        title: string;
        description: string;
        details_button: string;
    };
    country_select: {
        title: string;
        no_options: string;
    };
    tablet_button: {
        empty_title: string;
    };
    icons_picker: {
        title: string;
        cancel_button: string;
        confirm_button: string;
    };
    image_cropper_dialog: {
        title: string;
        zoom: string;
        rotation: string;
        save_button: string;
        cancel_button: string;
    };
    loading_content: {
        loading: string;
    };
    activity_card: {
        today: string;
        yesterday: string;
        this_week: string;
        this_month: string;
        previous_month: string;
        past: string;
        entity: string;
        device: string;
        duration: string;
        end_type: string;
        participants: string;
        application: string;
        application_battle_with: string;
        level_on_app: string;
        missed_call: string;
        rejected_call: string;
        call_started: string;
        drawing_sent: string;
        and: string;
        photo_sent: string;
        camera_used: string;
        response: string;
        video_message: string;
    };
    notification_card: {
        open_relationship_invite: string;
        relationship_invite: {
            message_sender: string;
            message_unknown: string;
        };
        user_signed_in: {
            message: string;
        };
        photo_sent: {
            message: string;
        };
        drawing_sent: {
            message: string;
        };
        without_license: {
            message: string;
        };
        missed_call: {
            message: string;
        };
        video_message: {
            message: string;
        };
        sos_request: {
            message: string;
        };
        reminder_response: {
            message: string;
            responses: {
                positive: string;
                negative: string;
                not_responding: string;
            };
        };
        relationship_invite_request_result: {
            message_accepted: string;
            message_declined: string;
        };
        caregiver_administrator_role_request_result: {
            message_accepted: string;
            message_declined: string;
        };
        relationship_role_change: {
            message_senior_myself: string;
            message_senior_other: string;
            message_caregiver_myself: string;
            message_caregiver_other: string;
            message_unknown_myself: string;
            message_unknown_other: string;
        };
        personal_data_sharing_preferences_change: {
            message: string;
            current_data_settings_title: string;
            can_access_profile_data: string;
            can_access_contacts: string;
            can_access_applications: string;
            can_access_multimedia: string;
            can_access_reminders: string;
            can_access_activity_feed: string;
        };
        relationship_end: {
            message_myself: string;
            message_other: string;
        };
    };
    section_list: {
        refresh: string;
        no_more_data: string;
        without_data: string;
        today: string;
        old_f: string;
        old_m: string;
    };
    activity_timeline: { title: string };
    clipboard_text_field: {
        copy_to_clipboard_button: string;
        copy_to_clipboard_tooltip_message: string;
        success: { copy_to_clipboard_success_message: string };
    };
    activity_end_types: {
        [ActivityEndType.UserAction]: string;
        [ActivityEndType.SystemTimeout]: string;
    };
    device_types: {
        [DeviceType.Handset]: string;
        [DeviceType.Tablet]: string;
        [DeviceType.Desktop]: string;
        [DeviceType.Laptop]: string;
        [DeviceType.Unknown]: string;
    };
    camera_facing: {
        [CameraFacing.Front]: string;
        [CameraFacing.Rear]: string;
        [CameraFacing.External]: string;
        [CameraFacing.Unknown]: string;
    };
    reminder_response_values: {
        [ReminderResponseValue.Positive]: string;
        [ReminderResponseValue.Negative]: string;
        [ReminderResponseValue.NotResponding]: string;
    };
    invite_states: {
        [InviteState.Accepted]: string;
        [InviteState.Cancelled]: string;
        [InviteState.Open]: string;
        [InviteState.Pending]: string;
        [InviteState.Refused]: string;
        [InviteState.RefusedBySenior]: string;
        [InviteState.WaitingForSeniorApproval]: string;
        [InviteState.AcceptedBySenior]: string;
        [InviteState.Expired]: string;
    };
    process_statuses: {
        [ProcessStatus.Done]: string;
        [ProcessStatus.InProgress]: string;
        [ProcessStatus.ToDo]: string;
        [ProcessStatus.Undefined]: string;
    };
}
