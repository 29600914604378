/**
 * Follows the logic status of a process lifecycle
 */
export enum ProcessStatus {
    /**
     * Process was not defined yet.
     */
    Undefined = 'undefined',

    /**
     * Process was open and ready to take changes.
     */
    ToDo = 'to-do',

    /**
     * Process is being worked on.
     */
    InProgress = 'in-progress',

    /**
     * Process is completed.
     */
    Done = 'done',
}
