/**
 * Follows the logic state of a invite lifecycle
 */
export enum InviteState {
    /**
     * Invite was just created. It represents the start of the process. [TO DO]
     */
    Open = 'open',

    /**
     * In some cases it is required that the senior approves the invite before it
     * gets sent to the target user. [IN PROGRESS]
     */
    WaitingForSeniorApproval = 'waiting-for-senior-approval',

    /**
     * Senior accepted to allow the current target to be invited. [IN PROGRESS]
     */
    AcceptedBySenior = 'accepted-by-senior',

    /**
     * Senior refused to allow the current target to be invited. [DONE]
     */
    RefusedBySenior = 'refused-by-senior',

    /**
     * After being opened or after being approved by the senior, the invite gets
     * in 'pending' state and it's ready for acceptance. [IN PROGRESS]
     */
    Pending = 'pending',

    /**
     * Invited target refused this invitation. [DONE]
     */
    Refused = 'refused',

    /**
     * Invited target accepted this invitation. [DONE]
     */
    Accepted = 'accepted',

    /**
     * Invite was cancelled for some reason. [DONE]
     */
    Cancelled = 'cancelled',

    /**
     * Invite expired. [DONE]
     */
    Expired = 'expired',
}
