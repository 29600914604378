import { ActivityEndType } from '../backend/enums/activity-end-type';
import { AffiliationType } from '../backend/enums/affiliation-type';
import { BackendLanguageTag } from '../backend/enums/backend-language-tag';
import { CameraFacing } from '../backend/enums/camera-facing';
import { CaregiverRelationshipRole } from '../backend/enums/caregiver-relationship-role';
import { DeviceType } from '../backend/enums/device-type';
import { InviteState } from '../backend/enums/invite-state';
import { ProcessStatus } from '../backend/enums/process-status';
import { RegionCode } from '../backend/enums/region-code';
import { RelationshipType } from '../backend/enums/relationship-type';
import { ReminderResponseValue } from '../backend/enums/reminder-response-value';
import { UserGender } from '../backend/enums/user-gender';
import { UserType } from '../backend/enums/user-type';
import { SignInError } from '../enums/sign-in-error';
import { LanguageTranslation } from './types';

export const pt_PT: LanguageTranslation = {
    legal_documents: {
        terms_and_conditions: 'Termos e Condições',
        privacy_policy: 'Política de Privacidade',
    },
    products: {
        bluebird: 'Sioslife',
        bluebird_with_gender: 'à Sioslife',
    },
    relationship_types: {
        [RelationshipType.Family]: 'Familiar',
        [RelationshipType.Friend]: 'Amigo',
        [RelationshipType.Healthcare]: 'Cuidador de saúde',
        [RelationshipType.Service]: 'Serviço',
        [RelationshipType.Other]: 'Outro',
    },
    user_types: {
        [UserType.Caregiver]: 'Cuidador',
        [UserType.Follower]: 'Seguidor',
        [UserType.Senior]: 'Sénior',
    },
    genders: {
        [UserGender.Female]: 'Mulher',
        [UserGender.Male]: 'Homem',
        [UserGender.Undisclosed]: 'Não divulgado',
        [UserGender.Unspecified]: 'Outro',
    },
    languages: {
        [BackendLanguageTag.ptPT]: 'Português (Portugal)',
        [BackendLanguageTag.enUS]: 'Inglês (EUA)',
        [BackendLanguageTag.esES]: 'Espanhol (Espanha)',
    },
    regions: { [RegionCode.PT]: 'Portugal', [RegionCode.ES]: 'Espanha' },
    roles: {
        [CaregiverRelationshipRole.Administrator]: 'Administrador',
        [CaregiverRelationshipRole.Contact]: 'Contacto',
        [CaregiverRelationshipRole.Member]: 'Membro',
    },
    religions: {
        african_traditional_and_diasporic: 'Tradicional e diáspora africana',
        agnostic: 'Agnosticismo',
        animism: 'Animismo',
        atheist: 'Ateísmo',
        baha_i: 'Baháʼí',
        buddhism: 'Budismo',
        cao_dai: 'Caodaísmo',
        chinese_traditional: 'Tradicional chinesa',
        christianity: 'Cristianismo',
        hinduism: 'Hinduísmo',
        islam: 'Islamismo',
        jainism: 'Jainismo',
        juche: 'Juche',
        judaism: 'Judaísmo',
        neo_paganism: 'Neopaganismo',
        nonreligious: 'Sem religião',
        rastafari: 'Rastafári',
        secular: 'Secularismo',
        shinto: 'Xintoísmo',
        sikhism: 'Sikhismo',
        spiritism: 'Espiritismo',
        tenrikyo: 'Tenrikyo',
        unitarian_universalism: 'Unitário-Universalismo',
        zoroastrianism: 'Zoroastrismo',
        primal_indigenous: 'Indígena',
        undisclosed: 'Não divulgado',
        unspecified: 'Outro',
    },
    affiliation_types: {
        [AffiliationType.Senior]: 'Sénior',
        [AffiliationType.Administrator]: 'Administrador',
        [AffiliationType.Manager]: 'Manager',
        [AffiliationType.Operator]: 'Operador',
        [AffiliationType.MedicalProfessional]: 'Profissional de saúde',
    },
    date_time_picker: {
        cancel_button: 'Cancelar',
        confirm_button: 'Confirmar',
    },
    login: {
        welcome: 'Olá Cuidador!',
        welcome_accept_invite: 'Entre na sua conta para aceitar o convite!',
        instruction: 'Introduza as suas credenciais para começar.',
        email_input: 'Endereço de e-mail',
        password_input: 'Palavra-passe',
        enter_button: 'Entrar',
        forgot_password: 'Esqueceu a sua palavra-passe?',
        validate_invite: 'Foi convidado para ser cuidador?',
        use_invite_code: 'Utilizar código de convite',
        login_blocked_instruction:
            'Descarregue e instale a aplicação {{appName}} para poder comunicar com os seus amigos e familiares e usufruir de todas as funcionalidades.',
        google_play_store_button: 'Descarregar Android',
        apple_store_button: 'Descarregar iOS',
        errors: {
            invalid_password: 'Palavra-passe inválida',
            [SignInError.Unknown]: 'Ocorreu um erro. Por favor, tente mais tarde.',
            [SignInError.InvalidEmail]: 'E-mail inválido',
            [SignInError.UserDisabled]: 'Utilizador desativado',
            [SignInError.UserNotFound]: 'Utilizador não encontrado',
            [SignInError.WrongPassword]: 'Palavra-passe errada',
            [SignInError.FailedToGetMe]:
                'Não foi possível carregar a informação do utilizador. Por favor, contacte com o Apoio ao Cliente.',
        },
    },
    recover_password: {
        title: 'Esqueceu a sua palavra-passe?',
        subtitle: 'Nós vamos ajudar!',
        instructions:
            'De forma a poder definir uma nova palavra-passe, por favor, indique o seu endereço de e-mail. Vamos enviar-lhe uma mensagem com uma hiperligação que lhe permitirá efetuar a mudança.',
        email_input: 'Endereço de e-mail',
        send_button: 'Enviar',
        password_remembered: 'Afinal já me lembrei da minha palavra-passe!',
        go_back_button: 'Voltar para o ecrã de Entrar',
        errors: {
            invalid_email: 'E-mail inválido',
            send_request_error_message: 'Um erro ocorreu. Não foi possível efetuar o pedido.',
        },
        success: {
            send_request_success_message:
                'As instruções para alterar a sua palavra-passe foram enviadas para o endereço de e-mail que forneceu.',
        },
    },
    replace_password: {
        title: 'Definir nova palavra-passe',
        instructions:
            'Ok! Bom trabalho! Vamos então definir uma nova palavra-passe. Escreva a sua nova palavra-passe nos dois espaços abaixo para terminar o processo.',
        new_password_input: 'Nova palavra-passe',
        repeat_password_input: 'Repetir a nova palavra-passe',
        submit_button: 'Confirmar',
        password_remembered: 'Afinal já me lembrei da minha palavra-passe!',
        go_back_button: 'Ir para o ecrã de Entrar',
        errors: {
            invalid_password_minimum_characters: 'A palavra-passe deve ter pelo menos 6 carateres',
            invalid_password_blank_spaces: 'A palavra-passe não pode começar nem terminar com espaços em branco',
            invalid_repeat_password_empty: 'Repita a palavra-passe',
            invalid_repeat_password_mismatch: 'Por favor, certifique-se que as palavras-passe são iguais',
            change_password_error_message: 'Um erro ocorreu. Não foi possível definir a sua nova palavra-passe.',
        },
        success: {
            change_password_success_message:
                'A sua nova palavra-passe foi definida. Pode agora entrar com a sua nova palavra-passe.',
        },
    },
    validate_invite: {
        title: 'Utilizar código de convite',
        instructions_1: 'Para ser cuidador {{brand}} deverá possuir um convite de acesso à plataforma.',
        instructions_2:
            'Caso já tenha solicitado um convite, por favor, verifique a sua caixa de mensagens de e-mail e/ou a sua caixa de mensagens de SMS e procure por uma mensagem {{brand}}. Nessa mensagem encontrará o código que deverá indicar abaixo.',
        code_input: 'Código do convite',
        send_button: 'Validar convite e avançar',
        errors: {
            invalid_code: 'Código inválido',
            invalid_invite_error_message:
                'O convite que tentou utilizar está inválido. Por favor, verifique se insiriu o código correto. Em caso afirmativo, solicite um novo convite.',
        },
    },
    accept_relationship_invite: {
        title: 'Aceitar convite para ser cuidador de',
        instructions:
            'Para ser cuidador deverá ter uma conta de acesso à plataforma {{brand}}. Se é a primeira vez que está a aceitar um convite, pressione em "{{button_create}}". Se já aceitou outros convites no passado, utilize a sua conta existente e pressione em "{{button_login}}".',
        create_account_button: 'Aceitar e criar nova conta',
        login_button: 'Entrar numa conta já existente',
        errors: {
            invalid_invite_error_message:
                'O convite que tentou utilizar está inválido. Por favor, solicite um novo convite.',
        },
    },
    notifications: {
        date: 'Data',
        time: 'Hora',
        title: 'Notificações',
        exit_button: 'Sair',
        read_all_notifications_normal_button: 'Marcar todas como lidas',
        read_all_notifications_loading_button: 'A ler...',
        read_all_notifications_success_button: 'Notificações lidas!',
        read_all_notifications_error_button: 'Erro a ler notificações',
        read_all_notifications_dialog_title: 'Marcar todas as notificações como lidas?',
        read_all_notifications_dialog_content:
            'Poderá voltar a marcar individualmente cada notificação como não lida posteriormente se assim o desejar.',
        read_all_notifications_dialog_action_yes_button: 'Sim, marcar',
        read_all_notifications_dialog_action_no_button: 'Cancelar',
        read_all_notifications_disabled_tooltip_message: 'Já marcou todas as notificações como lidas.',
        success: {
            read_all_notifications_success_message: 'As notificações foram todas marcadas como lidas.',
        },
    },
    push_notifications: {
        warnings: {
            unsubscribed_generic_warning_title: 'Não pode receber notificações em tempo real',
            unsubscribed_not_supported_warning_message:
                'O navegador que está a utilizar não suporta a receção de notificações. Por favor, utilize o Chrome, Firefox ou Edge para poder receber notificações em tempo real.',
            unsubscribed_error_warning_message: 'Um erro ocorreu pelo que não pode receber notificações em tempo real.',
            unsubscribed_permissions_denied_warning_message:
                'As permissões para receber notificações foram negadas. Por favor, ative as notificações para o navegador que está a utilizar.',
        },
    },
    relationship_invite_modal: {
        title: 'Aceitar o convite para ser cuidador',
        relationship_type_input: 'Qual o tipo de relacionamento que possui com {{name}}?',
        alert_description:
            'Está a utilizar uma conta com informação diferente da informação presente neste convite. Por favor, reveja com atenção se pretende continuar.',
        accept_button: 'Sim, aceito',
        decline_button: 'Não, recuso',
        relationship_already_exists_description:
            'Já é cuidador por ter aceite um convite anteriormente. Não necessita de fazer mais nada.',
        invite_done_used_description: 'O convite já foi utilizado. Não necessita de fazer mais nada.',
        invite_done_refused_description: 'O convite já foi recusado. Não necessita de fazer mais nada.',
        invite_done_accepted_description: 'O convite já foi aceite. Não necessita de fazer mais nada.',
        loading_invite_description_1: 'A carregar detalhes do convite...',
        loading_invite_description_2: 'Por favor, aguarde...',
        errors: {
            refuse_invite_error_message: 'Um erro ocorreu. Não foi possível recusar o convite.',
            accept_invite_error_message: 'Um erro ocorreu. Não foi possível aceitar o convite.',
            fetch_relationship_invite_error_message: 'Um erro ocorreu. Não foi possível abrir o convite.',
            fetch_relationship_invite_invalid_error_message: 'O convite que tentou abrir está inválido.',
        },
        success: {
            refuse_invite_success_message: 'O convite foi recusado. {{name}} não faz parte da sua lista de contactos.',
            accept_invite_success_message: 'O convite foi aceite. {{name}} faz agora parte da sua lista de contactos. ',
        },
    },
    settings: {
        title: 'Definições',
        exit_button: 'Sair',
        personal_information_section_title: 'Meu perfil',
        change_theme_section_title: 'Meu tema',
        legal_information_section_title: 'Informação legal',
        relationships_section_title: 'Minhas relações',
        affiliations_section_title: 'Minhas afiliações',
        logout_normal_button: 'Terminar sessão',
        logout_loading_button: 'A terminar sessão...',
        logout_error_button: 'Erro a terminar sessão',
        theme_input: 'Tema',
        dark_option: 'Escuro',
        light_option: 'Claro',
        system_option: 'Tema do sistema (automático)',
    },
    create_informal_caregiver_account: {
        register_title: 'Registo',
        essential_information_step_description:
            'Bem-vindo à plataforma {{brand}}! Vamos criar uma conta de utilizador para que se possa tornar cuidador e assim terminar o processo de aceitação do convite que recebeu.',
        essential_information_step: 'Informação essencial',
        personal_information_step: 'Informação pessoal',
        optional_information_step: 'Informação opcional',
        note_title: 'Nota',
        note_statement: 'Os dados indicados com "*" são obrigatórios para a criação da conta de acesso para cuidador.',
        continue_button: 'Continuar',
        create_account_button: 'Aceitar convite e criar conta',
        start_using_button: 'Começar a utilizar',
        go_back_to_login_button: 'Ir para o ecrã de Entrar',
        go_back_button: 'Voltar para trás',
        success_title: 'Sucesso!',
        success_description:
            'Parabéns! Acabou de se tornar utilizador da plataforma {{brand}}! Obrigado por se juntar a esta comunidade. Pode agora avançar para a sua conta de utilizador.',
        error_title: 'Não foi possível criar a conta de utilizador!',
        error_description:
            'Infelizmente algo falhou a criar a sua conta de utilizador. Por favor, contacte o Apoio ao Cliente para obter ajuda.',
        relationship_type_input: 'Tipo de relacionamento com {{name}}',
        email_address_input: 'Endereço de e-mail',
        password_input: 'Palavra-passe',
        repeat_password_input: 'Repetir a palavra-passe',
        legal_documents_title: 'Documentos legais',
        legal_documents_statement:
            'Confirmo que li e aceito as <0>Condições Gerais de Utilização</0> e a <1>Política de Privacidade e Proteção de Dados</1>.',
        name_input: 'Nome',
        mobile_number_input: 'Número de telemóvel',
        profile_picture_input: 'Foto de perfil',
        gender_input: 'Género',
        gender_placeholder_input: 'Selecione um género',
        language_input: 'Idioma',
        language_placeholder_input: 'Selecione um idioma',
        birthday_input: 'Data de nascimento',
        birthday_input_placeholder: 'Selecione uma data',
        tax_identification_number_input: 'Número de identificação fiscal',
        errors: {
            invalid_email: 'E-mail inválido',
            invalid_password_minimum_characters: 'A palavra-passe deve ter pelo menos 6 carateres',
            invalid_password_blank_spaces: 'A palavra-passe não pode começar nem terminar com espaços em branco',
            invalid_repeat_password_empty: 'Repita a palavra-passe',
            invalid_repeat_password_mismatch: 'Por favor, certifique-se que as palavras-passe são iguais',
            invalid_name: 'Nome inválido',
            invalid_name_maximum_characters: 'O nome deve ter menos do que {{max}} carateres',
            invalid_mobile_phone_number: 'Número de telemóvel inválido',
            invalid_tax_identification_number: 'Número de identificação fiscal inválido',
            empty_mobile_phone_number: 'Número de telemóvel em falta',
            legal_documents_not_accepted: 'É obrigatório aceitar os documentos legais',
            enter_account_error_message: 'Um erro ocorreu. Não foi possível entrar na sua conta.',
            invalid_invite_error_message: 'O convite que tentou utilizar para criar uma conta está inválido.',
        },
    },
    office: {
        profile_type_tabs: {
            informal_caregiver: 'Informal',
            professional_caregiver: 'Profissional',
            system_administrator: 'Admin. do Sistema',
            partner: 'Parceiro',
        },
        informal_caregiver: {
            tabs: {
                activity: 'Atividade',
                profile: 'Perfil',
                media: 'Multimédia',
                reminders: 'Lembretes',
            },
            add_menu: {
                button: 'Adicionar',
                title: 'O que pretende adicionar?',
                create_reminder: 'Lembrete',
                send_photo: 'Fotografia',
                send_video: 'Vídeo',
                send_invite: 'Convite para cuidador',
            },
            relationship_picker: {
                caption: 'Está a ver o perfil de:',
                dialog_title: 'Selecione outro contacto',
            },
        },
        professional_caregiver: {
            tabs: {
                start: 'Início',
                seniors: 'Seniores',
                applications: 'Aplicações',
                services: 'Serviços',
                professional_caregivers: 'Cuidadores',
                activity: 'Atividade',
                devices: 'Dispositivos',
            },
            affiliation_picker: {
                caption: 'Está a trabalhar na entidade:',
                dialog_title: 'Selecione outra entidade',
            },
        },
        system_administrator: {
            tabs: {
                start: 'Início',
                analytics: 'Estatística',
                activity: 'Atividade',
                devices: 'Dispositivos',
                apiDocs: 'Doc. da API',
                applications: 'Aplicações',
                entities: 'Entidades',
            },
        },
        partner: {
            tabs: {
                start: 'Início',
                apiDocs: 'Doc. da API',
            },
        },
        legacy_integration: {
            entity_services_disabled_alert_title: 'Não foi possível executar a ação.',
            entity_services_disabled_alert_message:
                'Lamentamos, mas não tem acesso a esta área. Por favor, contacte com o Apoio ao Cliente para mais informações.',
        },
    },
    seniors: {
        root: {
            title: 'Seniores',
            add_button: 'Adicionar sénior',
            search_input_placeholder: 'Pesquisar seniores',
            name_header: 'Nome',
            gender_header: 'Género',
            state_header: 'Estado',
            state_value_true: 'Ativo',
            state_value_false: 'Inativo',
            no_seniors: 'Não existem seniores.',
            deleted_senior_dialog_title: 'Perfil do sénior "{{name}}" apagado',
            deleted_senior_dialog_message: 'O perfil do sénior já não existe na lista de seniores da entidade.',
            deleted_senior_dialog_ok_button: 'Ok',
        },
        edit: {
            tabs: {
                profile: 'Perfil',
                caregivers: 'Cuidadores',
                applications: 'Aplicações',
                devices: 'Dispositivos',
                activity: 'Atividade',
                analytics: 'Estatísticas',
                cards: 'Cartões',
                health: 'Saúde',
            },
            caregivers: {
                invite_caregiver_button: 'Convidar cuidador',
                invites_history_button: 'Ver convites',
                no_caregivers: 'Não existem cuidadores.',
                name_header: 'Nome',
                relationship_type_header: 'Tipo',
                remove_relationship_header: 'Remover relação',
                remove_relationship_dialog_title:
                    'Remover a relação entre o sénior {{senior}} e o cuidador {{caregiver}}?',
                remove_relationship_dialog_message:
                    'Ao remover esta relação, o sénior {{senior}} não poderá mais comunicar com o cuidador {{caregiver}}.',
                remove_relationship_dialog_action_yes_button: 'Sim, remover',
                remove_relationship_dialog_action_no_button: 'Cancelar',
                errors: {
                    remove_relationship_error_message: 'Um erro ocorreu. Não foi possível remover a relação.',
                },
            },
            invites_history: {
                title: 'Histórico de convites',
                no_invites: 'Não existem convites.',
                name_header: 'Nome',
                contact_header: 'Contacto',
                state_header: 'Estado do convite',
                status_header: 'Estado do processo',
                cancel_invite_dialog_title: 'Cancelar o convite para {{caregiver}} ser cuidador de {{senior}}?',
                cancel_invite_dialog_message:
                    'Ao cancelar este convite, o cuidador {{caregiver}} não poderá aceitar o convite para comunicar com o sénior {{senior}}.',
                cancel_invite_dialog_action_yes_button: 'Sim, cancelar',
                cancel_invite_dialog_action_no_button: 'Não alterar o convite',
                errors: {
                    cancel_invite_error_message: 'Um erro ocorreu. Não foi possível cancelar o convite.',
                },
            },
            invite_caregiver: {
                title: 'Convidar cuidador',
                name_input: 'Nome',
                contact_method_input: 'Método de contacto',
                email_input: 'Endereço de e-mail',
                phone_number_input: 'Número de telemóvel',
                save_button: 'Enviar convite',
                saving_button: 'A enviar...',
                save_dialog_title: 'Enviar convite para {{name}}?',
                save_dialog_message:
                    'Ao enviar, {{name}} irá receber um convite para comunicar e ter acesso a algumas informações de {{senior}}.',
                save_dialog_action_cancel_button: 'Cancelar',
                save_dialog_action_save_button: 'Enviar',
                router_prompt_title: 'Sair sem enviar o convite?',
                router_prompt_message: 'Ao sair, perderá todas as informações inseridas no convite.',
                router_prompt_action_cancel_button: 'Cancelar',
                router_prompt_action_ok_button: 'Sim, sair',
                invite_caregiver_cancel_toast: 'O convite foi cancelado.',
                success: {
                    save_success_button: 'Enviado!',
                    invite_caregiver_success_message: 'O convite foi enviado para {{name}}.',
                },
                errors: {
                    error_on_save_button: 'Erro a enviar',
                    invalid_on_save_button: 'Existe informação inválida',
                    form_validation_invalid_field: 'Preencha este campo para completar o convite',
                    form_validation_invalid_phone_number: 'O número de telemóvel indicado não é válido',
                    form_validation_invalid_email: 'O endereço de e-mail indicado não é válido',
                },
            },
            applications: {
                search_input_placeholder: 'Pesquisar aplicações',
                name_header: 'Nome',
                category_header: 'Categoria',
                state_header: 'Estado',
                state_value_true: 'Ativo',
                state_value_false: 'Inativo',
                no_applications: 'Não existem aplicações.',
                enable_application_dialog_title: 'Ativar a aplicação {{name}}?',
                enable_application_dialog_message:
                    'Ao ativar a aplicação, ela estará disponível para o sénior {{name}}.',
                enable_application_dialog_action_yes_button: 'Sim, ativar',
                enable_application_dialog_action_no_button: 'Cancelar',
                disable_application_dialog_title: 'Desativar a aplicação {{name}}?',
                disable_application_dialog_message:
                    'Ao desativar a aplicação, ela deixará de estar disponível para o sénior {{name}}.',
                disable_application_dialog_action_yes_button: 'Sim, desativar',
                disable_application_dialog_action_no_button: 'Cancelar',
                errors: {
                    enable_application_error_message: 'Um erro ocorreu. Não foi possível ativar a aplicação.',
                    disable_application_error_message: 'Um erro ocorreu. Não foi possível desativar a aplicação.',
                    update_state_application_error_message:
                        'Um erro ocorreu. Não foi possível atualizar o estado da aplicação.',
                },
            },
            devices: {
                identifier_header: 'Identificador',
                type_header: 'Tipo',
                state_header: 'Estado',
                state_value_true: 'Ativo',
                state_value_false: 'Inativo',
                dissociate_device_header: 'Desassociar dispositivo',
                no_devices: 'Não existem dispositivos.',
                associate_device_button: 'Associar dispositivo',
                dissociate_device_dialog_title:
                    'Desassociar o dispositivo com identificação "{{identifier}}" de {{name}}?',
                dissociate_device_dialog_message:
                    'Ao desassociar o dispositivo, {{name}} não poderá mais utilizá-lo para desfruir do sistema Sioslife.',
                dissociate_device_dialog_action_yes_button: 'Sim, desassociar',
                dissociate_device_dialog_action_no_button: 'Cancelar',
                errors: {
                    dissociate_device_error_message: 'Um erro ocorreu. Não foi possível desassociar o dispositivo.',
                },
            },
            errors: {
                senior_does_not_exist_error: 'Não foi possível obter o sénior que tentou aceder.',
            },
        },
    },
    professional_caregivers: {
        root: {
            title: 'Cuidadores',
            invite_button: 'Convidar cuidador',
            search_input_placeholder: 'Pesquisar cuidadores',
            name_header: 'Nome',
            email_header: 'E-mail',
            affiliation_type_header: 'Tipo',
            state_header: 'Estado',
            state_value_true: 'Ativo',
            state_value_false: 'Inativo',
            no_professional_caregivers: 'Não existem cuidadores.',
        },
        edit: {
            tabs: {
                profile: 'Perfil',
                affiliations: 'Afiliações',
            },
            errors: {
                professional_caregiver_does_not_exist_error: 'Não foi possível obter o cuidador que tentou aceder.',
            },
        },
    },
    user_form: {
        title_create_senior: 'Adicionar sénior',
        title_edit_senior: 'Editar sénior',
        title_current_user_profile: 'Perfil',
        title_view_professional_caregiver_profile: 'Perfil do cuidador',
        delete_senior_button: 'Apagar este perfil',
        delete_senior_dialog_title: 'Apagar perfil do sénior "{{name}}"?',
        delete_senior_dialog_content:
            'Ao apagar este perfil, ele será eliminado permanentemente da lista de seniores da entidade.',
        delete_dialog_action_yes_button: 'Sim, apagar',
        delete_dialog_action_no_button: 'Cancelar',
        name_input: 'Nome',
        state_input: 'Estado',
        state_value_true: 'Ativo',
        state_value_false: 'Inativo',
        gender_input: 'Género',
        gender_placeholder_input: 'Selecione um género',
        birthday_input: 'Data de nascimento',
        birthday_placeholder_input: 'Selecione uma data',
        address_input: 'Morada',
        religion_input: 'Religião',
        religion_placeholder_input: 'Selecione uma religião',
        occupation_input: 'Profissão',
        language_input: 'Idioma',
        language_placeholder_input: 'Selecione um idioma',
        region_input: 'Região',
        region_placeholder_input: 'Selecione uma região',
        email_input: 'Endereço de e-mail',
        phone_number_input: 'Número de telemóvel',
        tax_payer_number_input: 'Número de identificação fiscal',
        profile_picture_input: 'Foto de perfil',
        id_input: 'Id',
        exit_button: 'Sair',
        save_button: 'Guardar',
        saving_button: 'A guardar...',
        deleting_button: 'A apagar...',
        create_senior_dialog_title: 'Guardar criação do sénior "{{name}}"?',
        create_senior_dialog_message: 'Ao guardar, o novo sénior será adicionado à lista de seniores.',
        save_senior_dialog_title: 'Guardar alterações no perfil do sénior "{{name}}"?',
        save_senior_dialog_message: 'Ao guardar, o sénior será atualizado com as informações recentemente inseridas.',
        save_current_user_profile_dialog_title: 'Guardar alterações no seu perfil?',
        save_current_user_profile_dialog_message:
            'Ao guardar, o seu perfil será atualizado com as informações recentemente inseridas.',
        save_dialog_action_cancel_button: 'Cancelar',
        save_dialog_action_save_button: 'Guardar',
        senior_router_prompt_edit_title: 'Sair sem guardar as alterações no perfil do sénior "{{name}}"?',
        senior_router_prompt_create_title: 'Sair sem guardar criação do sénior "{{name}}"?',
        senior_router_prompt_create_message:
            'Ao sair, o sénior não será guardado e todas as informações nele inseridas serão apagadas.',
        senior_router_prompt_edit_message:
            'Ao sair, perderá todas as alterações recentemente inseridas no perfil do sénior.',
        current_user_router_prompt_edit_title: 'Sair sem guardar as alterações no seu perfil?',
        current_user_router_prompt_edit_message:
            'Ao sair, perderá todas as alterações recentemente inseridas no seu perfil.',
        router_prompt_action_cancel_button: 'Cancelar',
        router_prompt_action_ok_button: 'Sim, sair',
        senior_create_cancel_toast: 'A criação do novo sénior foi cancelada.',
        senior_edit_cancel_toast: 'A edição do sénior "{{name}}" foi cancelada.',
        current_user_edit_cancel_toast: 'A edição do seu perfil foi cancelada.',
        current_user_edit_email_not_allowed_tooltip_title:
            'Não é possível alterar o seu endereço de e-mail atualmente.',
        current_user_edit_state_not_allowed_tooltip_title: 'Não é possível alterar o seu estado atualmente.',
        success: {
            save_success_button: 'Guardado!',
            delete_success_button: 'Apagado!',
            create_senior_success_message: 'O sénior "{{name}}" foi criado com sucesso!',
            edit_senior_success_message: 'As alterações no perfil do sénior foram guardadas.',
            edit_current_user_success_message: 'As alterações no seu perfil foram guardadas.',
            edit_current_user_app_language_to_chosen_language_success_message:
                'O idioma da aplicação foi atualizado para {{language}}.',
            edit_current_user_app_language_to_browser_language_success_message:
                'O idioma da aplicação foi atualizado para o idioma do seu navegador, {{language}}.',
            edit_current_user_app_language_to_default_language_success_message:
                'O idioma da aplicação foi atualizado para o idioma utilizado por defeito, {{language}}.',
        },
        errors: {
            error_on_save_button: 'Erro a guardar',
            invalid_on_save_button: 'Existe informação inválida',
            error_on_delete_senior_button: 'Erro ao apagar o sénior',
            senior_edit_form_validation_invalid_field: 'Preencha este campo para completar a edição do sénior',
            senior_create_form_validation_invalid_field: 'Preencha este campo para completar a criação do sénior',
            current_user_edit_form_validation_invalid_field:
                'Preencha este campo para completar a edição do seu perfil',
            form_validation_invalid_phone_number: 'O número de telemóvel indicado não é válido',
            form_validation_invalid_email: 'O endereço de e-mail indicado não é válido',
            form_validation_invalid_tax_payer_number: 'O número de identificação fiscal indicado não é válido',
            form_validation_invalid_name_maximum_characters: 'O nome deve ter menos do que {{max}} carateres',
        },
    },
    entity_services: {
        root: {
            title: 'Serviços',
            add_button: 'Adicionar serviço',
            search_input_placeholder: 'Pesquisar serviços',
            name_header: 'Nome',
            display_order_header: 'Ordem',
            state_header: 'Estado',
            state_value_true: 'Ativo',
            state_value_false: 'Inativo',
            no_services: 'Não existem serviços configurados.',
            deleted_service_dialog_title: 'Serviço "{{name}}" apagado',
            deleted_service_dialog_message1: '"{{name}}" foi apagado.',
            deleted_service_dialog_message2:
                'O serviço já não está disponível na aplicação {{caregiversAppName}} e no {{seniorsDeviceName}}.',
            deleted_service_dialog_ok_button: 'Ok',
        },
        edit: {
            title_create: 'Criar serviço',
            title_edit: 'Editar serviço',
            exit_button: 'Sair',
            save_button: 'Guardar',
            saving_button: 'A guardar...',
            title_input: 'Nome',
            state_input: 'Estado',
            phone_number_input: 'Número de contacto',
            phone_number_use_general_number: 'Utilizar número geral',
            phone_number_use_specific_number: 'Utilizar número específico',
            display_order_input: 'Ordem',
            description_input: 'Descrição',
            details_input: 'Detalhes',
            attach_document_input: 'Adicionar anexo PDF',
            document_informative_note_input: 'Mensagem sobre informação contratual',
            icon_input: 'Ícone',
            image_input: 'Imagem',
            button_preview: 'Visualização do botão',
            state_value_true: 'Ativo',
            state_value_false: 'Inativo',
            number_input: 'Número',
            upload: 'Anexar',
            remove_document: 'Remover',
            view_document: 'Ver',
            image_info:
                'A imagem deverá ser do tipo JPG/JPEG. Para melhores resultados deverá utilizar a resolução de 292x288px.',
            delete_button: 'Apagar serviço',
            deleting_button: 'A apagar...',
            delete_dialog_title: 'Apagar serviço "{{name}}"?',
            delete_dialog_content:
                'Ao apagar este serviço, todos os utilizadores da aplicação {{caregiversAppName}} e do {{seniorsDeviceName}} perderão o acesso ao mesmo. Além disso, o serviço também será apagado permanentemente da lista de serviços da entidade.',
            delete_dialog_action_yes_button: 'Sim, apagar',
            delete_dialog_action_no_button: 'Cancelar',
            router_prompt_edit_title: 'Sair sem guardar as alterações nos detalhes do serviço "{{name}}"?',
            router_prompt_create_title: 'Sair sem guardar criação do serviço "{{name}}"?',
            router_prompt_create_message:
                'Ao sair, o serviço não será guardado e todas as informações nele inseridas serão apagadas.',
            router_prompt_edit_message:
                'Ao sair, perderá todas as alterações recentemente inseridas nos detalhes do serviço.',
            router_prompt_action_cancel_button: 'Cancelar',
            router_prompt_action_ok_button: 'Sim, sair',
            create_service_dialog_title: 'Guardar criação do serviço "{{name}}"?',
            create_service_dialog_message: 'Ao guardar, o novo serviço será adicionado à lista de serviços.',
            save_service_dialog_title: 'Guardar alterações nos detalhes do serviço "{{name}}"?',
            save_service_dialog_message:
                'Ao guardar, o serviço será atualizado com as informações recentemente inseridas.',
            save_service_dialog_action_cancel_button: 'Cancelar',
            save_service_dialog_action_save_button: 'Guardar',
            service_create_cancel_toast: 'A criação do novo serviço foi cancelada.',
            service_edit_cancel_toast: 'A edição do serviço "{{name}}" foi cancelada.',
            errors: {
                error_on_save_button: 'Erro a guardar',
                invalid_on_save_button: 'Existe informação inválida',
                delete_service_fail_button: 'Erro ao apagar serviço',
                edit_form_validation_invalid_field: 'Preencha este campo para completar a edição do serviço',
                create_form_validation_invalid_field: 'Preencha este campo para completar a criação do serviço',
                create_form_validation_invalid_icon: 'Escolha um ícone para completar a criação do serviço',
                create_form_validation_invalid_image: 'Carregue uma imagem para completar a criação do serviço',
                form_validation_invalid_phone_number: 'O número indicado não é válido',
                service_does_not_exist_error: 'Não foi possível obter o serviço que tentou aceder.',
            },
            success: {
                save_success_button: 'Guardado!',
                delete_success_button: 'Apagado!',
                save_success_message: 'O serviço "{{name}}" foi criado com sucesso!',
                edit_success_message: 'As alterações nos detalhes do serviço foram guardadas.',
            },
        },
    },
    applications: {
        root: {
            title: 'Aplicações',
            search_input_placeholder: 'Pesquisar aplicações',
            name_header: 'Nome',
            category_header: 'Categoria',
            state_header: 'Estado',
            state_value_true: 'Ativo',
            state_value_false: 'Inativo',
            no_applications: 'Não existem aplicações.',
            enable_application_dialog_title: 'Ativar a aplicação {{name}}?',
            enable_application_dialog_message:
                'Ao ativar a aplicação, ela estará disponível para todos os seniores da entidade {{name}}.',
            enable_application_dialog_action_yes_button: 'Sim, ativar',
            enable_application_dialog_action_no_button: 'Cancelar',
            disable_application_dialog_title: 'Desativar a aplicação {{name}}?',
            disable_application_dialog_message:
                'Ao desativar a aplicação, ela deixará de estar disponível para todos os seniores da entidade {{name}}.',
            disable_application_dialog_action_yes_button: 'Sim, desativar',
            disable_application_dialog_action_no_button: 'Cancelar',
            errors: {
                enable_application_error_message: 'Um erro ocorreu. Não foi possível ativar a aplicação.',
                disable_application_error_message: 'Um erro ocorreu. Não foi possível desativar a aplicação.',
                update_state_application_error_message:
                    'Um erro ocorreu. Não foi possível atualizar o estado da aplicação.',
            },
        },
        edit: {
            tabs: {
                details: 'Detalhes',
                users: 'Utilizadores',
            },
            errors: {
                application_does_not_exist_error: 'Não foi possível obter a aplicação que tentou aceder.',
            },
        },
    },
    application_form: {
        name_input: 'Nome',
        state_input: 'Estado',
        state_value_true: 'Ativo',
        state_value_false: 'Inativo',
        description_input: 'Descrição',
        is_system_application_input: 'Aplicação do sistema',
        is_system_application_value_yes: 'Sim',
        is_system_application_value_no: 'Não',
        available_for_user_types_input: 'Tipos de utilizadores para os quais a aplicação está disponível',
        icon_input: 'Ícone',
        picture_input: 'Imagem',
        category_input: 'Categoria',
        activity_type_input: 'Tipo de atividade',
        windows_key_input: 'Chave Windows',
        android_key_input: 'Chave Android',
    },
    devices: {
        root: {
            title: 'Dispositivos',
            search_input_placeholder: 'Pesquisar dispositivos',
            identifier_header: 'Identificador',
            type_header: 'Tipo',
            state_header: 'Estado',
            state_value_true: 'Ativo',
            state_value_false: 'Inativo',
            no_devices: 'Não existem dispositivos.',
            add_button: 'Adicionar dispositivo',
        },
        edit: {
            tabs: {
                details: 'Detalhes',
                state: 'Estado',
            },
            errors: { device_does_not_exist_error: 'Não foi possível obter o dispositivo que tentou aceder.' },
        },
    },
    section_tabs: { exit_button: 'Sair' },
    occupation_input: { no_options: 'Sem opções', loading: 'A carregar...' },
    user_menu: {
        logout: 'Terminar sessão',
        profile: 'Perfil',
        errors: { logout_error_message: 'Um erro ocorreu. Não foi possível terminar a sessão.' },
    },
    error_box: {
        title: 'Não foi possível executar a ação.',
        description:
            'Lamentamos, mas ocorreu um erro ao tentar executar a ação. Por favor, contacte com o Apoio ao Cliente indicando os detalhes fornecidos.',
        details_button: 'Ver detalhes',
    },
    country_select: {
        title: 'Indicativo do país',
        no_options: 'Sem opções',
    },
    tablet_button: {
        empty_title: '(Título do botão)',
    },
    icons_picker: {
        title: 'Selecionador de ícones',
        cancel_button: 'Cancelar',
        confirm_button: 'Confirmar',
    },
    image_cropper_dialog: {
        title: 'Preparar imagem',
        zoom: 'Ampliação',
        rotation: 'Rotação',
        save_button: 'Guardar',
        cancel_button: 'Cancelar',
    },
    loading_content: {
        loading: 'A carregar...',
    },
    activity_card: {
        today: 'Hoje',
        yesterday: 'Ontem',
        this_week: 'Esta semana',
        this_month: 'Este mês',
        previous_month: 'No mês passado',
        past: 'Passado',
        entity: 'Entidade',
        device: 'Dispositivo',
        duration: 'Duração',
        end_type: 'Tipo de finalização',
        participants: 'Participantes',
        application: 'Aplicação',
        application_battle_with: 'com',
        level_on_app: 'Nível {{level}} na aplicação',
        missed_call: 'tentou ligar mas a chamada não foi atendida.',
        rejected_call: 'tentou ligar mas a chamada foi rejeitada.',
        call_started: 'telefonou para',
        drawing_sent: 'Desenho enviado para',
        and: 'e',
        photo_sent: 'Fotografia enviada para',
        camera_used: 'Câmera utilizada',
        response: 'Resposta',
        video_message: 'Mensagem de vídeo enviada por',
    },
    notification_card: {
        open_relationship_invite: 'Abrir convite',
        relationship_invite: {
            message_sender: '{{sender}} enviou-lhe um convite para adicionar {{target}} à sua lista de contactos.',
            message_unknown: 'Recebeu um convite para adicionar {{target}} à sua lista de contactos.',
        },
        user_signed_in: {
            message: '{{name}} iniciou sessão no Sioslife.',
        },
        photo_sent: {
            message: '{{name}} partilhou uma fotografia consigo.',
        },
        drawing_sent: {
            message: '{{name}} partilhou um desenho consigo.',
        },
        without_license: {
            message: '{{name}} não possui licença para usar os sistemas Sioslife.',
        },
        missed_call: {
            message: '{{name}} tentou ligar-lhe mas não obteve resposta.',
        },
        video_message: {
            message: '{{name}} enviou-lhe uma mensagem de vídeo.',
        },
        sos_request: {
            message: 'Pedido de ajuda SOS de {{name}}. Por favor, confirme se está tudo bem.',
        },
        reminder_response: {
            message: '{{name}} {{response}} ao lembrete "{{reminder}}".',
            responses: {
                positive: 'respondeu "Sim"',
                negative: 'respondeu "Não"',
                not_responding: 'não respondeu',
            },
        },
        relationship_invite_request_result: {
            message_accepted: '{{name}} aceitou {{target}} como contacto.',
            message_declined: '{{name}} não aceitou {{target}} como contacto.',
        },
        caregiver_administrator_role_request_result: {
            message_accepted: '{{name}} aceitou {{target}} como Administrador.',
            message_declined: '{{name}} não aceitou {{target}} como Administrador.',
        },
        relationship_role_change: {
            message_senior_myself: '{{name}} definiu o seu perfil como {{role}} do seu Sioslife.',
            message_senior_other: '{{name}} definiu {{target}} como {{role}} do seu Sioslife.',
            message_caregiver_myself: '{{name}} definiu o seu perfil como {{role}} do Sioslife de {{senior}}.',
            message_caregiver_other: '{{name}} definiu {{target}} como {{role}} do Sioslife de {{senior}}.',
            message_unknown_myself: 'O seu perfil foi definido como {{role}} do Sioslife de {{senior}}.',
            message_unknown_other: '{{target}} foi definido como {{role}} do Sioslife de {{senior}}.',
        },
        personal_data_sharing_preferences_change: {
            message: '{{name}} alterou as definições de partilha de dados do seu Sioslife.',
            current_data_settings_title: 'As definições atuais são:',
            can_access_profile_data: 'Informação de perfil',
            can_access_contacts: 'Contactos',
            can_access_applications: 'Aplicações',
            can_access_multimedia: 'Multimédia',
            can_access_reminders: 'Lembretes',
            can_access_activity_feed: 'Registo de atividade',
        },
        relationship_end: {
            message_myself:
                '{{name}} removeu o seu perfil da sua lista de contactos. A partir daqui não vão conseguir comunicar através dos sistemas Sioslife.',
            message_other:
                '{{name}} removeu {{target}} da sua lista de contactos. A partir daqui {{target}} não vai conseguir comunicar com {{name}} através dos sistemas Sioslife.',
        },
    },
    section_list: {
        refresh: 'Atualizar',
        no_more_data: 'Não existem mais dados',
        without_data: 'Não existem dados',
        today: 'Hoje',
        old_f: 'Mais antigas',
        old_m: 'Mais antigos',
    },
    activity_timeline: { title: 'Atividade' },
    clipboard_text_field: {
        copy_to_clipboard_button: 'Copiar',
        copy_to_clipboard_tooltip_message: 'Copiar para a área de transferência',
        success: { copy_to_clipboard_success_message: 'Texto copiado para a área de transferência!' },
    },
    activity_end_types: {
        [ActivityEndType.UserAction]: 'Ação do utilizador',
        [ActivityEndType.SystemTimeout]: 'Tempo de espera do Sistema',
    },
    device_types: {
        [DeviceType.Handset]: 'Telemóvel',
        [DeviceType.Tablet]: 'Tablet',
        [DeviceType.Desktop]: 'Computador',
        [DeviceType.Laptop]: 'Portátil',
        [DeviceType.Unknown]: 'Desconhecido',
    },
    camera_facing: {
        [CameraFacing.Front]: 'Câmara frontal',
        [CameraFacing.Rear]: 'Câmara traseira',
        [CameraFacing.External]: 'Câmara externa',
        [CameraFacing.Unknown]: 'Câmara desconhecida',
    },
    reminder_response_values: {
        [ReminderResponseValue.Positive]: 'Sim / Está bem',
        [ReminderResponseValue.Negative]: 'Não',
        [ReminderResponseValue.NotResponding]: 'Não respondeu',
    },
    invite_states: {
        [InviteState.Accepted]: 'Aceite',
        [InviteState.Cancelled]: 'Cancelado',
        [InviteState.Open]: 'Aberto',
        [InviteState.Pending]: 'Pendente',
        [InviteState.Refused]: 'Recusado',
        [InviteState.RefusedBySenior]: 'Recusado pelo sénior',
        [InviteState.WaitingForSeniorApproval]: 'Aguarda aprovação pelo sénior',
        [InviteState.AcceptedBySenior]: 'Aceite pelo sénior',
        [InviteState.Expired]: 'Expirado',
    },
    process_statuses: {
        [ProcessStatus.Done]: 'Concluído',
        [ProcessStatus.InProgress]: 'Em progresso',
        [ProcessStatus.ToDo]: 'Por fazer',
        [ProcessStatus.Undefined]: 'Não definido',
    },
};
