import moment from 'moment';
import { BackendLanguageTag } from '../backend/enums/backend-language-tag';
import { LanguageCode } from '../enums/language-code';
import { AppLanguageTag } from './types';
import i18n from 'i18next';
import { MomentLanguageCode } from '../enums/moment-language-code';

/**
 * Gets the app's language for i18n and moment according with the language set in the browser.
 * If the language of the browser is not available, the default is used language, which is Portuguese.
 *
 * Note: Later on we may need to update this in order to use the language kept in a local storage.
 */
export const getInitAppLanguage = async (): Promise<{
    appLanguage: AppLanguageTag;
    momentLanguage: MomentLanguageCode;
}> => {
    // Language to use (Default: 'pt_PT')
    let appLg: AppLanguageTag = AppLanguageTag.ptPT;

    // Moment language (Default: 'pt')
    let momentLg: MomentLanguageCode = MomentLanguageCode.ptPT;

    // Browser language code (Example: 'pt', 'en', 'es', etc)
    const browserLanguageCode: string | null = navigator.language ? navigator.language.split('-')[0] : null;

    // Search for the language
    switch (browserLanguageCode) {
        case LanguageCode.pt:
            appLg = AppLanguageTag.ptPT;
            momentLg = MomentLanguageCode.ptPT;
            break;
        case LanguageCode.en:
            appLg = AppLanguageTag.enUS;
            momentLg = MomentLanguageCode.enGB;
            break;
        case LanguageCode.es:
            appLg = AppLanguageTag.esES;
            momentLg = MomentLanguageCode.esES;
            break;
        default:
            // Default is Portuguese
            break;
    }

    // Return
    return {
        appLanguage: appLg,
        momentLanguage: momentLg,
    };
};

/**
 * Updates the app's language (i18n and moment).
 * If the language is not available, the browser's language will be used.
 * If the language of the browser is not set, the default language will be used, which is Portuguese.
 */
export const updateAppLanguage = async (language?: BackendLanguageTag) => {
    try {
        // i18n language (Default: 'pt_PT')
        let appLanguage: AppLanguageTag = AppLanguageTag.ptPT;

        // Moment language (Default: 'pt')
        let momentLanguage: MomentLanguageCode = MomentLanguageCode.ptPT;

        // Find the i18n and moment language
        if (language === BackendLanguageTag.ptPT) {
            appLanguage = AppLanguageTag.ptPT;
            momentLanguage = MomentLanguageCode.ptPT;
        } else if (language === BackendLanguageTag.enUS) {
            appLanguage = AppLanguageTag.enUS;
            momentLanguage = MomentLanguageCode.enGB;
        } else if (language === BackendLanguageTag.esES) {
            appLanguage = AppLanguageTag.esES;
            momentLanguage = MomentLanguageCode.esES;
        } else {
            // Browser language code (Example: 'pt', 'en', 'es', etc)
            const browserLanguageCode: string | null = navigator.language ? navigator.language.split('-')[0] : null;

            // Search for the languages
            switch (browserLanguageCode) {
                case LanguageCode.pt:
                    appLanguage = AppLanguageTag.ptPT;
                    momentLanguage = MomentLanguageCode.ptPT;
                    break;
                case LanguageCode.en:
                    appLanguage = AppLanguageTag.enUS;
                    momentLanguage = MomentLanguageCode.enGB;
                    break;
                case LanguageCode.es:
                    appLanguage = AppLanguageTag.esES;
                    momentLanguage = MomentLanguageCode.esES;
                    break;
                default:
                    // Default is Portuguese
                    break;
            }
        }

        // Change the moment language
        if (momentLanguage !== moment.locale()) {
            await moment.locale(momentLanguage);

            // Log
            console.debug('✅ Language for moment set to', momentLanguage);
        }

        // Change the i18n language
        if (appLanguage !== i18n.language) {
            await i18n.changeLanguage(appLanguage);

            // Log
            console.debug('✅ Language for i18n set to', appLanguage);
        }
    } catch (error) {
        // Log error
        console.debug('🔴 Could not update the language.', error);
    }
};
