import { AppLanguageTag, LanguagesResource } from './types';
import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as moment from 'moment';
import 'moment/min/locales';
import { getInitAppLanguage } from './functions';
import { pt_PT } from './pt_PT';
import { en_US } from './en_US';
import { es_ES } from './es_ES';

// Define Language Resources
export const resources: LanguagesResource = {
    [AppLanguageTag.ptPT]: {
        translation: pt_PT,
    },
    [AppLanguageTag.enUS]: {
        translation: en_US,
    },
    [AppLanguageTag.esES]: {
        translation: es_ES,
    },
};

// Custom language detector for i18n
// https://www.i18next.com/misc/creating-own-plugins#languagedetector
const LanguageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    // If this is set to true, your detect function receives a callback function
    // that you should call with your language. Useful to retrieve your language
    // stored in AsyncStorage for example.
    async: true,
    init: () => {},
    detect: async (callback) => {
        // Get the app's language
        const { appLanguage, momentLanguage } = await getInitAppLanguage();

        // Set moment locale
        moment.locale(momentLanguage);

        // Log
        console.debug('✅ Language for moment set to', momentLanguage);

        // Return it
        callback(appLanguage);

        // Log
        console.debug('✅ Language for i18n set to', appLanguage);
    },
    cacheUserLanguage: () => {},
};

// Init i18n
export default i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // Translations resources
        resources,
        // Fallback Language
        fallbackLng: AppLanguageTag.ptPT,
        // Separator
        keySeparator: '.',
        // Other configs
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });
