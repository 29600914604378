/**
 * @swagger
 *  components:
 *    schemas:
 *      RegionCode:
 *        type: string
 *        description: |
 *          The code of the current Country (ISO 3166-1 alpha-2) in use by the app/user.
 *
 *          `PT` - Portugal.
 *
 *          `ES` - Spain.
 *
 *        enum:
 *          - PT
 *          - ES
 */
/**
 * Country Code ISO (ISO 3166-1 alpha-2)
 * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 * (We can even segment this by region inside the country. Read more at:
 * https://en.wikipedia.org/wiki/ISO_3166-2:PT )
 */
export enum RegionCode {
    /**
     * Portugal
     */
    PT = 'PT',

    /**
     * Spain
     */
    ES = 'ES',
}
