export enum AffiliationType {
    /**
     * Senior
     */
    Senior = 'senior',

    /**
     * Administrator
     */
    Administrator = 'administrator',

    /**
     * Manager
     */
    Manager = 'manager',

    /**
     * Operator
     */
    Operator = 'operator',

    /**
     * Medical Data
     */
    MedicalProfessional = 'medical-professional',
}
