/**
 * Reminder Response Value
 */
export enum ReminderResponseValue {
    /**
     * Reminder was confirmed / had a positive response.
     */
    Positive = 'positive',

    /**
     * Reminder was denied / had a negative response.
     */
    Negative = 'negative',

    /**
     * For some unknown reason, user is not responding to the reminder.
     */
    NotResponding = 'not-responding',
}
