/**
 * User type
 */
export enum UserType {
    /**
     * Caregiver Entity Administrator
     *
     * Takes care of seniors on one or multiple entities.
     */
    Caregiver = 'caregiver',

    /**
     * Senior
     *
     * Our most precious role. Seniors can have multiple "devices",
     * each device can belong to an entity.
     */
    Senior = 'senior',

    /**
     * Follower
     *
     * A public and general role to lead to a client
     */
    Follower = 'follower',
}
